import React from 'react';
import config from '../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

class TripFilters extends React.Component {
  handleDateChangeRaw(e) {
    e.preventDefault();
    return;
  }

  isMobile() {
    return window.$(window).width() < 1023;
  }

  render() {
    return (
      <form className="container-fluid align-items-center trip-filters">
        <div className="row">
          <div className="col-lg col-md-4 col-sm-6 col-6 departingCity">
            {/* <div className="travel-form--label">From</div> */}
            <div className="input-group">
              <select value={this.props.data.departCity} name='departCity' onChange={this.props.handleUserInput.bind(this)} className="custom-select depart-city" id="inlineFormInputGroup">
                <option value=''>From</option>
                {config.cities.map((stop, index) => {
                  return (
                    <option key={index} value={stop.value}>{stop.label}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className="col-lg col-md-4 col-sm-6 col-6 arrivingCity">
            {/* <div className="travel-form--label">To</div> */}
            <div className="input-group">
              <select value={this.props.data.arriveCity} name='arriveCity' onChange={this.props.handleUserInput.bind(this)} className="custom-select arrive-city" id="inlineFormInputGroup">
                <option value=''>To</option>
                {this.props.data.arriveCities.map((stop, index) => {
                  return (
                    <option key={index} value={stop.value}>{stop.label}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='col-lg-2 col-md-4 col-sm-6 col-6'>
              {/* <div className="travel-form--label">Leaving</div> */}
              <div className="trip-date-picker-wrap">
                <DatePicker placeholderText='Leaving' readOnly={true} onChangeRaw={this.handleDateChangeRaw.bind(this)} withPortal={this.isMobile()} className="form-control arrive-date" minDate={moment()} dateFormat="M/D/YY" id="example-datepicker" selected={this.props.data.departDate} fixedHeight={true} onChange={this.props.handleDepartDateChange.bind(this)} />
              </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-6 col-6">
            {/* <div className="travel-form--label">{!this.props.data.returnDate ? 'Returning' : <span>Remove Return <i onClick={this.props.removeReturn.bind(this)} className="fa fa-times-circle"></i></span> }</div> */}
              <div className="trip-date-picker-wrap">
                <DatePicker placeholderText='Returning' readOnly={true} onChangeRaw={this.handleDateChangeRaw.bind(this)}  withPortal={this.isMobile()} className="form-control return-date" minDate={this.props.data.departDate} dateFormat="M/D/YY" id="example-datepicker" selected={this.props.data.returnDate} fixedHeight={true} onChange={this.props.handleReturnDateChange.bind(this)} />
              </div>
              <small>Optional</small>
          </div>
          <div className='col-lg-2 col-md-6 col-sm-12'>
            {/* <div className="travel-form--label">Passengers</div> */}
            <div className="chooseNoOfSeats">
              <select onChange={this.props.handleUserInput.bind(this)} name="seats" value={this.props.data.seats} className="custom-select num-of-seats" id="inlineFormInputGroup">
                <option disabled selected>Number of Seats</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
                <option value="32">32</option>
                <option value="33">33</option>
                <option value="34">34</option>
                <option value="35">35</option>
                <option value="36">36</option>
                <option value="37">37</option>
                <option value="38">38</option>
                <option value="39">39</option>
                <option value="40">40</option>
              </select>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default TripFilters;