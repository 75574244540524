import React from 'react';
import ReservationService from '../../services/reservation';
import UserStore from '../../stores/UserStore';
import ReservationStore from '../../stores/ReservationStore';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';
import config from '../../config';
import eliteIcon from '../../img/elite svg.svg';
import { history as browserHistory } from '../../helpers/history';

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.onUpcomingChange = this.onUpcomingChange.bind(this);
    this.state = {
      loading: false,
      user: UserStore.getUser(),
      reservations: null,
      error: '',
      downloading: null,
      resending: null,
      cities: config.cities
    }
  }

  getStopLabel(stop) {
    return _.find(this.state.cities, {value: stop}).label;
  }

  getStopMetro(stop) {
    return _.find(this.state.cities, {value: stop}).metro;
  }

  getStopPickup(stop) {
    return _.find(this.state.cities, {value: stop}).address;
  }

  getTime(time) {
    return moment(time, "HH:mm:ss").format("hh:mm A");
  }

  onUpcomingChange(){
    console.log(ReservationStore.getUpcoming());
    let result = ReservationStore.getUpcoming();
    if(result) {
      this.setState({reservations: result});
    }
  }

  getUpcomingReservations() {
    this.setState({loading: true, error: ''});
    ReservationService.getUpcomingReservations()
    .then(() => {
      this.setState({loading: false})
    })
    .catch((err) => {
      this.setState({loading: false, error: err.response.statusText});
    });
  }

  componentDidMount() {
    ReservationStore.addUpcomingChangeListener(this.onUpcomingChange);
    this.getUpcomingReservations();
  }

  componentWillUnmount() {
    ReservationStore.removeUpcomingChangeListener(this.onUpcomingChange);
  }

  mailTicket(number) {
    this.setState({loading: true, resending: number, downloading: null});
    ReservationService.mailTicket(number)
    .then(() => {
      this.setState({loading: false, resending: null})
    })
    .catch((err) => {
      this.setState({loading: false, error: err.response.statusText, resending: null});
    });
  }

  createBlob(data, filename){
    var blob = new Blob([data], {type: 'application/pdf'});
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(blob, filename);
    }
    else {
      var blobURL = window.URL.createObjectURL(blob);
      var tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', filename);
      if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }
  }

  download(code) {
    this.setState({loading: true, downloading: code, resending: null});
    ReservationService.download(code)
    .then((result) => {
      this.createBlob(result.body, 'ticket.pdf');
      this.setState({loading: false, downloading: null});
    })
    .catch((err) => {
      this.setState({loading: false, error: err.toString(), downloading: null});
    });
  }

  editSeats(number) {
    browserHistory.push('/edit-trip?reservationNumber=' + number);
  }

  render() {
    return (
      this.state.user && <div className="overview">
        <div className="heading">Welcome <span>{this.state.user.firstName}</span></div>
        <div className="row clearfix vouchers">
          <div className="col-3">
            <div className="pointsNum">{this.state.user.vouchers}</div> 
            <div className="points">POINTS</div>
          </div>
          <div className="col-3">
            <div className="pointsNum"><sup>$</sup>{this.state.user.credit}</div>
            <div className="points">CASH/CREDIT</div>
          </div>
        </div>
        <div>
          <div className="heading">Upcoming Trips</div>
          <div className="upcoming">
            {this.state.reservations && this.state.reservations.map((item, index) => {
              return (
                <div className="card" key={index}>
                  <div className="card-header">
                    <div className="res-number"><div className="only-desktop reservation-label">RESERVATION</div># <span>{config.formatReservationNumber(item.reservationNumber)}</span>
                      {item.type === 'Elite' ? <div className="elite-bus-icon"><img height="15px" src={eliteIcon} /></div> : null}
                    </div>
                    <div>
                      <a href="javascript:void(0)" className="view-history" onClick={this.props.setCurrentStep.bind(this, 2)}>View History</a>
                      <div className="dropdown dropdown-menu-right inline">
                        <a href="javascript:void(0)" className="dropdown-toggle options">Options <b className="caret"></b></a>
                        <ul className="dropdown-menu">
                          <li><a className={(this.state.resending === item.reservationNumber ? 'active': '')} href="javascript:void(0)" onClick={this.mailTicket.bind(this, item.reservationNumber)}>Re-send Email Confirmation</a></li>
                          <li><a className={(this.state.downloading === item.reservationNumber ? 'active': '')} href="javascript:void(0)" onClick={this.download.bind(this, item.reservationNumber)}>Print Ticket</a></li>
                          <li><a href="javascript:void(0)" onClick={this.editSeats.bind(this, item.reservationNumber)}>Reschedule Seat(s)</a></li>
                          <li><a href="javascript:void(0)" onClick={this.editSeats.bind(this, item.reservationNumber)}>Cancel Seat(s)</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row clearfix">
                      <div className="col-lg-7">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="sub-fields">DATE/TIME</div>
                            <div className="sub-fields-value">{moment(item.slot, 'YYYY-MM-DD').format('MM/DD/YY')} {moment(item.depart_stop.time, 'HH:mm:ss').format('hh:mm a')}</div>
                          </div>
                          <div className="col-sm-6">
                            <div className="sub-fields">ROUTE</div>
                            <div className="sub-fields-value">{this.getStopLabel(item.depart_stop.city)} to {this.getStopLabel(item.arrive_stop.city)}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="row">
                          <div className="col-sm-3 col-6">
                            <div className="sub-fields">SEATS</div>
                            <div className="sub-fields-value">{item.seats}</div>
                          </div>
                          <div className="col-sm-3 col-6">
                            <div className="sub-fields">POINTS</div>
                            <div className="sub-fields-value">+{item.pointsEarned}</div>
                          </div>
                          <div className="col-sm-3 col-6">
                            <div className="sub-fields">TOTAL</div>
                            <div className="sub-fields-value">{numeral(item.ticketValue).format('$0,0[.]00')}</div>
                          </div>
                          <div className="col-sm-3 col-6">
                            <div className="sub-fields">STATUS</div>
                            <div className="sub-fields-value active">Active</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
            {this.state.loading &&
              <div className="fond">
                <div className="contener_general">
                  <div className="contener_mixte"><div className="ballcolor ball_1">&nbsp;</div></div>
                  <div className="contener_mixte"><div className="ballcolor ball_2">&nbsp;</div></div>
                  <div className="contener_mixte"><div className="ballcolor ball_3">&nbsp;</div></div>
                  <div className="contener_mixte"><div className="ballcolor ball_4">&nbsp;</div></div>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="free-ticket-info">
            <div>Every {config.vouchersForFreeTicket} points equals a standard bus free ticket</div>
            <div className="youhaveearn">You have <span>{Math.floor(this.state.user.vouchers/config.vouchersForFreeTicket)}</span> free tickets (standard bus) and need <span>{config.vouchersForFreeTicket - (this.state.user.vouchers%config.vouchersForFreeTicket)}</span> more points to earn your next free ticket</div>
        </div>  
      </div>
    )
  }
}

export default Overview;