// ScheduleStore.js
import jwt from 'jwt-decode';
import Dispatcher from '../dispatcher/Dispatcher';
import { EventEmitter } from 'events';

class ScheduleStoreClass extends EventEmitter {

  constructor() {
    super();
    this.schedules = [];
    this.returnSchedules = [];
    Dispatcher.register(this.actionDispatcher.bind(this));
  }

  addChangeListener(callback) {
    this.on('change', callback);
  }

  removeChangeListener(callback) {
    this.removeListener('change', callback);
  }

  addReturnListener(callback) {
    this.on('returnChange', callback);
  }

  removeReturnListener(callback) {
    this.removeListener('returnChange', callback);
  }

  getSchedules() {
    return this.schedules;
  }

  getReturnSchedules() {
    return this.returnSchedules;
  }

  emitChange() {
    this.emit('change');
  }

  emitReturnChange() {
    this.emit('returnChange');
  }

  actionDispatcher(payload) {
    switch (payload.action.type) {
      case 'SET_SCHEDULES':
        this.schedules = payload.action.data.schedules;
        this.emitChange();
        break;
      case 'SET_RETURN_SCHEDULES':
        this.returnSchedules = payload.action.data.schedules;
        this.emitReturnChange();
        break;
      default:
        break;
    }
  }
}
const ScheduleStore = new ScheduleStoreClass();
export default ScheduleStore;