export default {
  apiUrl: 'https://api.bustripper.webarysites.com/api/',
//   apiUrl: 'http://localhost:5000/api/',
  cities: [
		{code: 'NY', value: 'new york', label: 'New York, NY', address: '254 W 31 St NY, NY', metro: 'MTA subway lines A, C, E, [8th Ave] 1,2,3, [7th Ave] outside of the Penn Station. B, D, F, M, [6th Ave.]', zip: 10001, state: 'New York'},
		{code: 'WASB', value: 'bethesda', label: 'Bethesda, MD', address: '7200 47th Street, Bethesda, MD', metro: 'Bethesda Station, Red Line', zip: 20814, state: 'Maryland'},
		{code: 'WASA', value: 'arlington', label: 'Arlington, VA', address: '1901 N. Moore Street, Arlington, VA', metro: 'Rosslyn Station, Blue and Orange Line', zip: 22209, state: 'Virginia'}
  ],
  vouchersForFreeTicket: 6,
  formatReservationNumber: function(number) {
  	if (!number) {
  		return;
  	}
		let str = '';
	  let initialChars = 7;
		if (number.length > 15) {
			initialChars = 9;
		}
		str = number.substr(0, initialChars) + '-';
		str += number.substr(initialChars, 1) + '-';
		str += number.substr(initialChars + 1, 4) + '-';
		if(number.indexOf('NY') > -1) {
			str += number.substr(initialChars + 5, 2) + '-';
			str += number.substr(initialChars + 7);
		}
		else if (number.indexOf('WA') > -1) {
			str += number.substr(initialChars + 5, 4) + '-';
			str += number.substr(initialChars + 9);
		}
		return str;
  }
}
