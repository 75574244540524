// login.js
import React from 'react';
import UserStore from '../stores/UserStore';
import Order from '../services/order';
import numeral from 'numeral';
import moment from 'moment';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import confirmIcon from '../img/confirm_icon.png';
import 'react-datepicker/dist/react-datepicker.css';

class AddGift extends React.Component {

  constructor() {
    super();
    this.emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.state = {
      gift: {
        senderName: '',
        senderEmail: '',
        recipientName: '',
        recipientEmail: '',
        message: '',
        delivery: 'now',
        deliveryDate: undefined,
        amount: 25
      },
      card: {
        cardHolder: '',
        number: '',
        month: '',
        year: '',
        cvv: '',
        zip: '',
        phone: '',
        city: '',
        state: '',
        billingAddress: '',
        streetAddress: ''
      },
      user: UserStore.getUser() || {
        firstName: '',
        lastName: '',
        email: '',
        password: ''
      },
      recieveOffers: false,
      months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      years: [],
      acceptTerms: false,
      error: '',
      showCustom: false,
      loading: false,
      success: false
    };
  }

  handleTermsAccept() {
    this.setState({ acceptTerms: !this.state.acceptTerms });
  }

  handleOffers() {
    this.setState({ recieveOffers: !this.state.recieveOffers });
  }

  componentDidUpdate() {
    if(UserStore.getUser() && UserStore.getUser().id && !this.state.user.id) {
      this.setState({user: UserStore.getUser()});
    }
  }

  handleDelivery() {
    let gift = this.state.gift;
    if(gift.delivery === 'now') {
      gift.delivery = 'later';
    }
    else {
      gift.delivery = 'now';
      gift.deliveryDate = undefined;
    }
    this.setState({gift: gift});
  }

  handleDate(value) {
    let gift = this.state.gift;
    gift.deliveryDate = value;
    this.setState({gift: gift});
  }

  componentDidMount() {
    let years = [];
    let start = new Date().getFullYear();
    let end = start + 11;
    for (let i = start; i < end; i++) {
      years.push(i.toString().substr(2));
    }
    this.setState({ years: years });
  }

  handleCardInput(e) {
    let card = this.state.card;
    card[e.target.name] = e.target.value;
    this.setState({ card: card });
    if(e.target.required && !e.target.value) {
      let error = {};
      error['card_'+e.target.name+'_error'] = 'Required';
      this.setState(error);
    }
    else if(e.target.name === 'cvv' && /\d{3}/.test(e.target.value) === false) {
      let error = {};
      error['card_'+e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
    else if(e.target.name === 'number' && /\d{4}[\s.-]\d{4}[\s.-]\d{4}[\s.-]\d{4}/.test(e.target.value) === false) {
      let error = {};
      error['card_'+e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
    else if(this.refs[e.target.name].validity && !this.refs[e.target.name].validity.valid) {
      let error = {};
      error['card_'+e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
    else {
      let error = {};
      error['card_'+e.target.name+'_error'] = '';
      this.setState(error);
    }
  }

  handleGiftInput(e) {
    let gift = this.state.gift;
    gift[e.target.name] = e.target.value;
    this.setState({ gift: gift });
    if(e.target.required && !e.target.value) {
      let error = {};
      error['gift_'+e.target.name+'_error'] = 'Required';
      this.setState(error);
    }
    else if(e.target.name === 'senderEmail' && e.target.value && this.emailRegex.test(e.target.value) === false) {
      let error = {};
      error['gift_'+e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
    else if(e.target.name === 'recipientEmail' && e.target.value && this.emailRegex.test(e.target.value) === false) {
      let error = {};
      error['gift_'+e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
    else if(this.refs[e.target.name].validity && !this.refs[e.target.name].validity.valid) {
      let error = {};
      error['gift_'+e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
    else {
      let error = {};
      error['gift_'+e.target.name+'_error'] = '';
      this.setState(error);
    }
  }

  handleUserInput(e) {
    this.setState({['user_'+e.target.name+'_error']: ''});
    let user = this.state.user;
    user[e.target.name] = e.target.value;
    this.setState({ user: user });
    if(e.target.required && !e.target.value) {
      let error = {};
      error['user_'+e.target.name+'_error'] = 'Required';
      this.setState(error);
    }
    else if(e.target.name === 'phone' && e.target.value && /\(\d{3}\)[\s.-]\d{3}[\s.-]\d{4}/.test(e.target.value) === false) {
      let error = {};
      error['user_'+e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
    else if(e.target.name === 'email' && e.target.value && this.emailRegex.test(e.target.value) === false) {
      let error = {};
      error['user_'+e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
    else if(this.refs[e.target.name].validity && !this.refs[e.target.name].validity.valid) {
      let error = {};
      error['user_'+e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
  }

  customAmount() {
    let gift = this.state.gift;
    gift.amount = '';
    this.setState({gift: gift});
  }

  validateData() {
    if (this.state.user.id) {
      let obj = {};
      for (let key in this.state.card) {
        if (!this.state.card[key]) {
          obj['card_'+key+'_error'] = 'Required';
        }
        else if(key === 'cvv' && /\d{3}/.test(this.state.card.cvv) === false) {
          obj['card_'+key+'_error'] = 'Invalid';
        }
        else if(key === 'number' && /\d{4}[\s.-]\d{4}[\s.-]\d{4}[\s.-]\d{4}/.test(this.state.card.number) === false) {
          obj['card_'+key+'_error'] = 'Invalid';
        }
        else if(this.refs[key].validity && !this.refs[key].validity.valid) {
          obj['card_'+key+'_error'] = 'Invalid';
        }
        else {
          this.setState({['card_'+key+'_error']: ''});
        }
      }
      for (let key in this.state.gift) {
        if(this.refs[key] && key !== 'message') {
          if (!this.state.gift[key]) {
            obj['gift_'+key+'_error'] = 'Required';
          }
          else if(this.refs[key].validity && !this.refs[key].validity.valid) {
            obj['gift_'+key+'_error'] = 'Invalid';
          }
          else if(key === 'senderEmail' && this.state.gift.senderEmail && this.emailRegex.test(this.state.gift.senderEmail) === false) {
            obj['gift_'+key+'_error'] = 'Invalid';
          }
          else if(key === 'recipientEmail' && this.state.gift.recipientEmail && this.emailRegex.test(this.state.gift.recipientEmail) === false) {
            obj['gift_'+key+'_error'] = 'Invalid';
          }
          else {
            this.setState({['gift_'+key+'_error']: ''});
          }
        }
      }
      if (Object.keys(obj).length > 0) {
        this.setState(obj);
        return false;
      }
    }
    else {
      let obj = {};
      for (let key in this.state.card) {
        console.log(key)
        if (!this.state.card[key]) {
          obj['card_'+key+'_error'] = 'Required';
        }
        else if(key === 'cvv' && /\d{3}/.test(this.state.card.cvv) === false) {
          obj['card_'+key+'_error'] = 'Invalid';
        }
        else if(key === 'number' && /\d{4}[\s.-]\d{4}[\s.-]\d{4}[\s.-]\d{4}/.test(this.state.card.number) === false) {
          obj['card_'+key+'_error'] = 'Invalid';
        }
        else if(this.refs[key] && this.refs[key].validity && !this.refs[key].validity.valid) {
          obj['card_'+key+'_error'] = 'Invalid';
        }
        else {
          this.setState({['card_'+key+'_error']: ''});
        }
      }
      for (let key in this.state.gift) {
        if(this.refs[key] && key !== 'message') {
          if (!this.state.gift[key]) {
            obj['gift_'+key+'_error'] = 'Required';
          }
          else if(this.refs[key].validity && !this.refs[key].validity.valid) {
            obj['gift_'+key+'_error'] = 'Invalid';
          }
          else if(key === 'senderEmail' && this.state.gift.senderEmail && this.emailRegex.test(this.state.gift.senderEmail) === false) {
            obj['gift_'+key+'_error'] = 'Invalid';
          }
          else if(key === 'recipientEmail' && this.state.gift.recipientEmail && this.emailRegex.test(this.state.gift.recipientEmail) === false) {
            obj['gift_'+key+'_error'] = 'Invalid';
          }
          else {
            this.setState({['gift_'+key+'_error']: ''});
          }
        }
      }
      for (let field in this.state.user) {
        if (!this.state.user[field]) {
          obj['user_'+field+'_error'] = 'Required';
        }
        else if(field === 'phone' && this.state.user.phone && /\(\d{3}\)[\s.-]\d{3}[\s.-]\d{4}/.test(this.state.user.phone) === false) {
          obj['user_'+field+'_error'] = 'Invalid';
        }
        else if(field === 'email' && this.state.user.email && this.emailRegex.test(this.state.user.email) === false) {
          obj['user_'+field+'_error'] = 'Invalid';
        }
        else if(this.refs[field].validity && !this.refs[field].validity.valid) {
          obj['user_'+field+'_error'] = 'Invalid';
        }
        else {
          this.setState({['user_'+field+'_error']: ''});
        }
      }
      if (Object.keys(obj).length > 0) {
        this.setState(obj);
        return false;
      }
    }
    return true;
  }

  save(e) {
    e.preventDefault();
    this.setState({submitted: true, success: false});
    let isValid = this.validateData();
    if(!isValid) {
      return;
    }
    this.setState({loading: true, error: ''});
    let body = {
      card: JSON.parse(JSON.stringify(this.state.card)),
      gift: JSON.parse(JSON.stringify(this.state.gift))
    };
    body.card.address = this.state.card.billingAddress + ' ' + this.state.card.streetAddress;
    body.card.cardNumber = this.state.card.number.split('-').join('');
    body.card.expiryDate = '' + this.state.card.month + '' + this.state.card.year;
    if(body.gift.deliveryDate) {
      body.gift.deliveryDate = moment(body.gift.deliveryDate).format('YYYY-MM-DD');
    }
    if (!this.state.user.id) {
      body.user = {
        email: this.state.user.email,
        firstName: this.state.user.firstName,
        lastName: this.state.user.lastName,
        phone: this.state.user.phone,
        password: this.state.user.password
      };
      Order.createGift(body)
      .then(result => {
        console.log(result);
        this.setState({success: true, loading: false});
      })
      .catch(err => {
        console.log(JSON.stringify(err));
        if(err && typeof(err) == 'object' && JSON.stringify(err).indexOf('users_email_unique') > -1) {
          this.setState({loading: false, error: 'Email already exists'});
          return;
        }
        let error = '';
        if(err && err.response && err.response.body && err.response.body.message) {
          error = err.response.body.message;
        }
        else if(err && err.response && err.response.statusText){
          error = err.response.statusText;
        }
        else {
          error = 'Internal server error';
        }
        this.setState({loading: false, error: error});
      });
    }
    else if (this.state.user.id) {
      body.userId = this.state.user.id;
      Order.createGift(body)
      .then(result => {
        console.log(result);
        this.setState({success: true, loading: false});
      })
      .catch(err => {
        let error = '';
        if(err && err.response && err.response.body && err.response.body.message) {
          error = err.response.body.message;
        }
        else {
          error = err.response.statusText;
        }
        this.setState({loading: false, error: error});
      });
    }
  }

  handleAmount(val) {
    if(val === 'custom') {
      let gift = this.state.gift;
      gift.amount = '';
      this.setState({gift: gift, showCustom: true});
    }
    else {
      let gift = this.state.gift;
      gift.amount = val;
      this.setState({gift: gift, showCustom: false}); 
    }
  }

  handleDateChangeRaw(e) {
    e.preventDefault();
    return;
  }

  render() {
    return (
      <div>
        <div className="add-gift-banner">Tripper Bus Travel Gift Cards</div>
        {!this.state.success ?
        <div className="container editTrip addGiftWrapper">
          <div className="header-content text-center">
            <h4>Select the amount you’d like to give.</h4>
            <p>Select the amount you'd like to purchase as a gift card. The gift card will be emailed as a redeemable code to the email address that you choose.</p>
            <div className="radioGroup">
              <label className="radioBtn">
                <input type="radio" name="r1" onChange={this.handleAmount.bind(this, 25)} checked={this.state.gift.amount === 25} />
                <div className="btn-checkmark">$25</div>
              </label>
              <label className="radioBtn">
                <input type="radio" name="r1" onChange={this.handleAmount.bind(this, 50)} checked={this.state.gift.amount === 50} /> 
                <div className="btn-checkmark">$50</div>
              </label>
              <label className="radioBtn">
                <input type="radio" name="r1" onChange={this.handleAmount.bind(this, 100)} checked={this.state.gift.amount === 100} /> 
                <div className="btn-checkmark">$100</div>
              </label>
              <label className="radioBtn">
                <input type="radio" name="r1" onChange={this.handleAmount.bind(this, 250)} checked={this.state.gift.amount === 250} /> 
                <div className="btn-checkmark">$250</div>
              </label>
              <label className="radioBtn">
                 <input type="radio" name="r1" onChange={this.handleAmount.bind(this, 'custom')} checked={this.state.showCustom} /> 
                 <div className="btn-checkmark">Custom</div>
              </label>
            </div>

            <div className="row customAmountWrapper">
              <div className="col-sm-3">
                {this.state.showCustom && <div className="form-group">
                  <div className="form-label text-left">Custom Amount you’d like to enter</div>
                  <div className="input-group">
                    <span className="currency">$</span>
                    <input min={1} required type="number" ref="amount" value={this.state.gift.amount} onChange={this.handleGiftInput.bind(this)} name="amount" className="form-control" />
                  </div>
                  <div className="error text-left">{this.state.gift_amount_error}</div>
                </div>}
              </div>
              <div className="col-sm-9 text-right">
                  <div className="form-label">TOTAL AMOUNT</div>
                  <div className="totalAmount">{numeral(this.state.gift.amount).format('$0.00')}</div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h3>Card Details</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="form-label">Your Name</div>
                    <input ref="senderName" required name="senderName" value={this.state.gift.senderName} onChange={this.handleGiftInput.bind(this)} type="text" className='form-control' />
                    <div className="error">{this.state.gift_senderName_error}</div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="form-label">Your Email</div>
                    <input ref="senderEmail" required name="senderEmail" value={this.state.gift.senderEmail} onChange={this.handleGiftInput.bind(this)} className='form-control' />
                    <div className="error">{this.state.gift_senderEmail_error}</div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="form-label">Recipient Name</div>
                    <input ref="recipientName" required name="recipientName" value={this.state.gift.recipientName} onChange={this.handleGiftInput.bind(this)} type="text" className='form-control' />
                    <div className="error">{this.state.gift_recipientName_error}</div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="form-label">Recipient Email</div>
                    <input ref="recipientEmail" required name="recipientEmail" value={this.state.gift.recipientEmail} onChange={this.handleGiftInput.bind(this)} className='form-control' />
                    <div className="error">{this.state.gift_recipientEmail_error}</div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="form-label">Gift Message (Optional)</div>
                <textarea className="form-control" value={this.state.gift.message} onChange={this.handleGiftInput.bind(this)} name="message" ref="message"></textarea>
              </div>
              <div className="form-group">
                <div className="form-label">Delivery Date</div>
                <label className="radio">
                  <input ref="delivery" name="delivery" type="radio" name="radio02" checked={this.state.gift.delivery === 'now'} onChange={this.handleDelivery.bind(this)} />
                  <span className="checkmark"></span>
                  Send Now
                </label>
                <label className="radio">
                  <input ref="delivery" name="delivery" type="radio" name="radio02" checked={this.state.gift.delivery === 'later'} onChange={this.handleDelivery.bind(this)} />
                  <span className="checkmark"></span>
                  Choose Another Date
                </label>
              </div>
              {this.state.gift.delivery === 'later' && <div className="form-group">
                <DatePicker onChangeRaw={this.handleDateChangeRaw.bind(this)} className="form-control" minDate={moment()} dateFormat="M/D/YY" id="example-datepicker" selected={this.state.gift.deliveryDate} fixedHeight={true} onChange={this.handleDate.bind(this)} />
              </div>}
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h3>Payment</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <div className="form-label">Name on Card</div>
                    <input required ref="cardHolder" name="cardHolder" value={this.state.card.cardHolder} onChange={this.handleCardInput.bind(this)} type="text" className="form-control" />
                    <div className="error">{this.state.card_cardHolder_error}</div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <div className="form-label">Card Number</div>
                    <InputMask required mask="9999-9999-9999-9999" ref="number" onChange={this.handleCardInput.bind(this)} value={this.state.card.number} name="number" required className='form-control' />
                    <div className="error">{this.state.card_number_error}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="form-label">Card Expiry Month</div>
                    <select ref="month" required name="month" value={this.state.card.month} onChange={this.handleCardInput.bind(this)} className='form-control'>
                      <option value=''>Select</option>
                      {this.state.months.map((item, index) => {
                        return (
                          <option key={index}>{item}</option>
                        )
                      })}
                    </select>
                    <div className="error">{this.state.card_month_error}</div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="form-label">Card Expiry Year</div>
                    <select ref="year" required name="year" value={this.state.card.year} onChange={this.handleCardInput.bind(this)} className='form-control'>
                      <option value=''>Select</option>
                      {this.state.years.map((item, index) => {
                        return (
                          <option key={index}>{item}</option>
                        )
                      })}
                    </select>
                    <div className="error">{this.state.card_year_error}</div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="form-label">CVV Number</div>
                    <InputMask required mask="999" ref="cvv" onChange={this.handleCardInput.bind(this)} value={this.state.card.cvv} name="cvv" className='form-control' />
                    <div className="error">{this.state.card_cvv_error}</div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="form-label">Billing Address</div>
                <input ref="billingAddress" required name="billingAddress" value={this.state.card.billingAddress} onChange={this.handleCardInput.bind(this)} type="text"  className='form-control' />
                <div className="error">{this.state.card_billingAddress_error}</div>
              </div>
              <div className="form-group">
                <div className="form-label">Street Address</div>
                <input ref="streetAddress" required name="streetAddress" value={this.state.card.streetAddress} onChange={this.handleCardInput.bind(this)} type="text"  className='form-control' />
                <div className="error">{this.state.card_streetAddress_error}</div>
              </div>

              <div className="row">
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="form-label">City</div>
                    <input ref="city" required name="city" value={this.state.card.city} onChange={this.handleCardInput.bind(this)} type="text"  className='form-control' />
                    <div className="error">{this.state.card_city_error}</div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="form-label">State/Region</div>
                    <input ref="state" required name="state" value={this.state.card.state} onChange={this.handleCardInput.bind(this)} type="text"  className='form-control' />
                    <div className="error">{this.state.card_state_error}</div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="form-label">Zip / Postal Code</div>
                    <input ref="zip" required name="zip" value={this.state.card.zip} onChange={this.handleCardInput.bind(this)} type="text" className='form-control' maxLength={5} />
                    <div className="error">{this.state.card_zip_error}</div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="form-label">Your Phone</div>
                    <InputMask mask="(999) 999-9999" ref="phone" onChange={this.handleCardInput.bind(this)} value={this.state.card.phone} name="phone" required  className='form-control' />
                    <div className="error">{this.state.card_phone_error}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {(!this.state.user || !this.state.user.id) && <div className="card">
            <div className="card-header">
              <h3>Create an Account</h3>
            </div>
            <div className="card-body">
              <div className="form-group pb-2">
                <p></p>
              </div>
              <div className="row">
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="form-label">First Name</div>
                    <input ref="firstName" required name="firstName" onChange={this.handleUserInput.bind(this)} value={this.state.user.firstName} type="text" className='form-control' />
                    <div className="error">{this.state.user_firstName_error}</div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="form-label">Last Name</div>
                    <input ref="lastName" required name="lastName" onChange={this.handleUserInput.bind(this)} value={this.state.user.lastName} type="text" className='form-control' />
                    <div className="error">{this.state.user_lastName_error}</div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="form-label">Email</div>
                    <input ref="email" required name="email" onChange={this.handleUserInput.bind(this)} value={this.state.user.email} type="email" className='form-control' />
                    <div className="error">{this.state.user_email_error}</div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="form-label">Password</div>
                    <input ref="password" required name="password" onChange={this.handleUserInput.bind(this)} value={this.state.user.password} type="password" className='form-control' />
                    <div className="error">{this.state.user_password_error}</div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="checkbox">
                  <input type="checkbox" checked={this.state.recieveOffers} onChange={this.handleOffers.bind(this)} />  
                  <span className="checkmark"></span>
                    Get the latest deals, news, and promotions via email
                </label>
            </div>
            <div className="form-group">
                <label className="checkbox">
                  <input type="checkbox" checked={this.state.acceptTerms} onChange={this.handleTermsAccept.bind(this)} /> 
                  <span className="checkmark"></span>
                  By clicking Review Order, I expressly agree to accept Tripperbus’s &nbsp; 
                  <a href="https://tripperbus.com/terms-and-conditions/">Terms of Use</a> and <a href="https://tripperbus.com/privacy-policy/">Privacy Policy</a>
                </label>
                {this.state.submitted && !this.state.acceptTerms && <div className="error">Please accept the terms.</div>}
              </div>
            </div>
          </div>}
          <div className="form-group text-right">
            <button disabled={this.state.loading || !this.state.gift.amount} onClick={this.save.bind(this)} type="button" className="btn btn-blue">Place Order</button>
          </div>
          {this.state.error && <div className="error text-right server-error">{this.state.error}</div>}
          {this.state.success && <div className="success server-success">Successfully Purchased.</div>}
        </div>
        :
        <div>
          <div className="container editTrip addGiftWrapper tickets-confirmed">
            <div className="content-body">
              <div className="header-content text-center">
                    <div className="form-group"><img src={confirmIcon} alt="" /></div>
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <h4>Thank you! Your Gift Card is ready and will be delivered at the date you selected.</h4>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      }
      </div>
    );
  }
}

export default AddGift;
