import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { history } from './helpers/history';
import App from './components';
import Home from './components/home';
import EditTrip from './components/editTrip';
import TicketConfirmed from './components/ticketConfirmed';
import ManageAccount from './components/manageAccount';
import Register from './components/register';
import Login from './components/login';
import AddGift from './components/addGift';
import ForgotPassword from './components/forgotPassword';
import ResetPassword from './components/resetPassword';
import EnsureLoggedInContainer from './components/ensure-logged-in-container';

const Routes = () => (
  <Router history={history}>
    <App>
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/forgot-password' component={ForgotPassword} />
        <Route path='/reset-password/:token' component={ResetPassword} />
        <Route path='/registration' component={Register} />
        <Route path='/' exact component={Home}/>
        <Route path='/edit-trip' component={EditTrip}/>
        <Route path='/tickets-confirmed' component={TicketConfirmed}/>
        <Route path='/add-gift-card' component={AddGift}/>
        <Route component={EnsureLoggedInContainer}>
          <Route path='/dashboard' component={ManageAccount}/>
        </Route>
        <Redirect from="*" to='/' component={Home}/>
      </Switch>
    </App>
  </Router>
);

export default Routes;