// home.js
import React from 'react';
import moment from 'moment';
import ReservationService from '../services/reservation';
import _ from 'lodash';
import config from '../config';
import confirmIcon from '../img/confirm_icon.png';

class TicketConfirmed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      departCity: '',
      arriveCity: '',
      departDate: moment().startOf('day'),
      returnDate: moment().startOf('day'),
      departTime: '12:00:00',
      returnTime: '12:00:00',
      seats: '',
      departTripCode: null,
      returnTripCode: null,
      confirmationCode: null,
      cities: config.cities
    };
  }
  
  getStopLabel(stop) {
    console.log(stop);
    return _.find(this.state.cities, {value: stop}).label;
  }

  getStopPickup(stop) {
    return _.find(this.state.cities, {value: stop}).address;
  }

  componentDidMount() {
    window.$("html, body").animate({ scrollTop: 0 }, 'fast');
    let obj = {};
    if(this.props.location.query.departCity) {
      obj.departCity = this.props.location.query.departCity;
    }
    if(this.props.location.query.arriveCity) {
      obj.arriveCity = this.props.location.query.arriveCity;
    }
    if(this.props.location.query.departStop) {
      obj.depart_stop = this.props.location.query.departStop;
    }
    if(this.props.location.query.arriveStop) {
      obj.arrive_stop = this.props.location.query.arriveStop;
    }
    if(this.props.location.query.departStop1) {
      obj.depart_stop1 = this.props.location.query.departStop1;
    }
    if(this.props.location.query.arriveStop1) {
      obj.arrive_stop1 = this.props.location.query.arriveStop1;
    }
    if(this.props.location.query.departDate) {
      obj.departDate = this.props.location.query.departDate;
    }
    if(this.props.location.query.returnDate) {
      obj.returnDate = this.props.location.query.returnDate;
    }
    if(this.props.location.query.departTime) {
      obj.departTime = this.props.location.query.departTime;
    }
    if(this.props.location.query.returnTime) {
      obj.returnTime = this.props.location.query.returnTime;
    }
    if(this.props.location.query.seats) {
      obj.seats = parseInt(this.props.location.query.seats);
    }
    if(this.props.location.query.departTripCode) {
      obj.departTripCode = this.props.location.query.departTripCode;
    }
    if(this.props.location.query.returnTripCode) {
      obj.returnTripCode = this.props.location.query.returnTripCode;
    }
    if(this.props.location.query.confirmationCode) {
      obj.confirmationCode = this.props.location.query.confirmationCode;
    }
    if(Object.keys(obj).length > 0) {
      this.setState(obj);
    }
  }

  createBlob(data, filename){
    var blob = new Blob([data], {type: 'application/pdf'});
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(blob, filename);
    }
    else {
      var blobURL = window.URL.createObjectURL(blob);
      var tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', filename);
      if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }
  }

  download(code) {
    ReservationService.download(code)
    .then((result) => {
      this.createBlob(result.body, 'ticket.pdf');
    })
    .catch((err) => {
      this.setState({loading: false, error: err.toString()});
    });
  }

  render() {
  	return (
      <div>
        <div className="tickets-confirmed-banner">Booking Confirmation</div>
        <div className="container editTrip addGiftWrapper tickets-confirmed">
          <div className="content-body">
            <div className="header-content text-center">
                  <div className="form-group"><img src={confirmIcon} alt="" /></div>
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <h4>Your trip has been confirmed</h4>
                    {this.state.confirmationCode && <p>We have created an account with the email that you entered and email you the password reset instructions, sign in to your account to view and manage your reservations.</p>}
                    <p>You will recieve your itinerary shortly in your email. You can return to our site at anytime and 
                      check the status of your trip. If you need to make any changes to your reservation please be
                      ready to use your ticket code number
                    </p>
                  </div>
                </div>
              </div>
              <div className="card ticket-confirmed-card">
                <div className="card-header">
                  <h3>Your Trip Details</h3>
                </div>
                <div className="card-body clearfix">
                  {this.state.departTripCode && <div className="row trip-row">
                    <div className="col-md-8">
                      <div>
                        <label>Seat/s</label>:
                        <span>{this.state.seats}</span>
                      </div>
                      <div>
                        <label>Date & time</label>:
                        <span>{this.state.departDate} at <span className="bold">{moment(this.state.departTime, 'HH:mm:ss').format('hh:mm a')}</span></span>
                      </div>
                      <div>
                        <label>Departing trip</label>:
                        <span>{this.getStopLabel(this.state.departCity)} to {this.getStopLabel(this.state.arriveCity)}</span>
                      </div>
                      <div>
                        <label>Ticket code</label>:
                        <span>{config.formatReservationNumber(this.state.departTripCode)}</span>
                      </div>
                      <div>
                        <label>Pick up</label>:
                        <span>{this.state.depart_stop}</span>
                      </div>
                      <div>
                        <label>Drop off</label>:
                        <span>{this.state.arrive_stop}</span>
                      </div>
                    </div>
                    <div className="col-md-4 down-btn">
                      <button type="button" className="btn btn-blue" onClick={this.download.bind(this, this.state.departTripCode)}>Download PDF</button>
                    </div>
                  </div>}
                  {
                    this.state.returnTripCode &&
                    <div className="row trip-row first-trip">
                      <div className="col-md-8">
                        <div>
                          <label>Date & time</label>:
                          <span>{this.state.returnDate} at <span className="bold">{moment(this.state.returnTime, 'HH:mm:ss').format('hh:mm a')}</span></span>
                        </div>
                        <div>
                          <label>Departing trip</label>:
                          <span>{this.getStopLabel(this.state.arriveCity)} to {this.getStopLabel(this.state.departCity)}</span>
                        </div>
                        <div>
                          <label>Ticket code</label>:
                          <span>{config.formatReservationNumber(this.state.returnTripCode)}</span>
                        </div>
                        <div>
                          <label>Pick up</label>:
                          <span>{this.state.depart_stop1}</span>
                        </div>
                        <div>
                          <label>Drop off</label>:
                          <span>{this.state.arrive_stop1}</span>
                        </div>
                      </div>
                      <div className="col-md-4 down-btn">
                        <button type="button" className="btn btn-blue" onClick={this.download.bind(this, this.state.returnTripCode)}>Download PDF</button>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="text-center be-sure-join">Be Sure to join our <span className="blue-text"><a target="_blank" href="https://tripperbus.com/rewards">Loyalty Rewards Program</a></span> to earn free travel!</div>
          </div>
        </div>
      </div>
  	)
  }
}

export default TicketConfirmed;