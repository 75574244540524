import React from 'react';
import ReservationService from '../../services/reservation';
import {Link} from 'react-router-dom';
import UserStore from '../../stores/UserStore';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.onUserChange = this.onUserChange.bind(this);
    this.state = {
      loading: false,
      email: UserStore.getUser() ? UserStore.getUser().email : '',
      reservationNumber: '',
      err: '',
      user: UserStore.getUser()
    }
  }

  handleUserInput(e){
    this.setState({[e.target.name]: e.target.value});
  }

  onUserChange() {
    this.setState({user: UserStore.getUser()});
  }

  findReservation() {
    if(!this.state.reservationNumber || !this.state.email) {
      return;
    }
    this.props.clearReservation(this.state.email);
    this.setState({loading: true, err: ''});
    let criteria = {
      email: this.state.email,
      reservationNumber: this.state.reservationNumber.split('-').join('')
    };
    ReservationService.getByEmail(criteria)
    .then(() => {
    })
    .catch((err) => {
      let error = '';
      console.log(JSON.stringify(err))
      if(err && err.response && err.response.text) {
        error = err.response.text;
      }
      else {
        error = err.response.statusText;
      }
      this.setState({loading: false, err: error});
    });
  }

  componentDidMount() {
    console.log(this.props)
    if(this.props.location.query.reservationNumber && this.props.location.query.email) {
      this.setState({reservationNumber: this.props.location.query.reservationNumber, email: this.props.location.query.email}, () => {
        this.findReservation();
      });
    }
    else if(this.props.location.query.reservationNumber) {
      this.setState({reservationNumber: this.props.location.query.reservationNumber}, () => {
        this.findReservation();
      });
    }
  }

  render() {
    return (
      <div className="container">
      <div className="col-12 confirmTrip editTrip">
        <div className="editTripBox">
          <div className="header-content text-center">
            <h4><strong>Let us find your trip details.</strong> Provide us some information.</h4>
            <p>Use this form if you already have a valid reservation with us and want to edit it or cancel it. We will show you on the next page all the various options.</p>
          </div>
          <div className="row form-group clearfix justify-content-center">
            <div className="col-sm-4">
              <label className="formLable">E-MAIL</label>
              <input required type="email" ref="email" name="email" disabled={this.state.user} value={this.state.email} onChange={this.handleUserInput.bind(this)} className="form-control mb-15" />
            </div>
            <div className="col-sm-4">
              <label className="formLable">RESERVATION NUMBER</label>
              <input required ref="reservationNumber" name="reservationNumber" value={this.state.reservationNumber} onChange={this.handleUserInput.bind(this)} className="form-control" />
            </div>
      
            <div className="col-sm-2 edit-trip-button">
              <label className="formLable d-block">&nbsp;</label>
              <button type="button" disabled={this.state.loading} onClick={this.findReservation.bind(this)} className="btn btn-blue">EDIT TRIP</button>
            </div>
          </div>
          {this.state.err && <div className="error text-right">{this.state.err}</div>}
          <div className="note text-center">
            <p>Please note: Any change must be made up to midnight prior to your day of travel and can be done online using this form. If you would like to change your reservation on your travel day, you may call our office at 718-834-9214, up to two hours prior.</p>
          </div>
        </div>
        {!this.state.user && <div className="optional-account text-center">
          <h4>You can also sign in to your account <span className="optional">(optional)</span></h4>
          <Link to='/login'><button type="button" className="btn btn-blue">LOGIN</button></Link>
        </div>}
      </div>
      </div>
    )
  }
}

export default Search;