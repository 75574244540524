import React from 'react';
import videoIcon from '../../img/video_icon.png';
import wifiIcon from '../../img/wifi_icon.png';
import updateIcon from '../../img/update_icon.png';
import chargerIcon from '../../img/charger_icon.png';
import expressIcon from '../../img/express_icon.png';
import noResultsImage from '../../img/noresults.svg';
import eliteIcon from '../../img/elite svg.svg';
import {Link} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import _ from 'lodash';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  checkExpress(stops) {
    return ((_.findIndex(stops, function(stop) { return stop.stop.city === 'bethesda' }) === -1) && (_.findIndex(stops, function(stop) { return stop.stop.city === 'arlington' }) > -1) && this.props.data.arriveCity !== 'bethesda');
  }

  getBusDescription(type) {
    if(type === 'Elite') {
      return `<ul class="bus-tooltip">
        <li>ELITE Executive coach with 34 seats instead of 56.</li>
        <li>More leg room</li>
        <li>Leather captains chair seating</li>
        <li>Reclining Seats</li>
        <li>Individual trays</li>
        <li>Complimentary movies and tv series on personal device</li>
        <li>Wifi</li>
        <li>Electrical Outlets</li>
      `;
    }
  }

  getDuration(time1, time2) {
    console.log(time1, time2)
    var startTime = moment(time1, "HH:mm:ss");
    var endTime = moment(time2, "HH:mm:ss");
    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = Math.round(((parseInt(duration.asMinutes())%60)/60) * 100) / 100;
    return ((hours + minutes) + ' - ' + (hours + minutes + 0.5) + ' hours');
  }

  render() {
  	return (
  		<div>
        <ReactTooltip />               
  			{this.props.data.departCity && this.props.data.arriveCity && this.props.data.departDate && this.props.data.seats && <section id="returning-trip" className="trip-section">
          <div className="container departing-trip-wrap">
            <div className="row">

              {this.props.data.departCity && this.props.data.arriveCity && <div className="col-md-12 departing-trip-title step-a">
                <i className="fa fa-arrow-up"></i>
                 Departing Trip: <span className="trip-loc">{this.props.getStopLabel(this.props.data.departCity)}</span> to <span className="trip-loc">{this.props.getStopLabel(this.props.data.arriveCity)}</span>
              </div>}

              <div className="col-md-12 date-controls">
                <ul className="nav nav-pills nav-fill">
                <li className="date-controls-btn" onClick={this.props.previousWeek.bind(this, 1)}><span className="fa fa-caret-left"></span></li>
                  {this.props.data.futureDeparts.map((day, index) => {
                    return (
                      <li className={"nav-item " + (this.props.checkPastDate(day.date) ? "inactive" : "")} key={index}>
                        <a href="javascript:void(0)" onClick={this.props.checkFutureDepart.bind(this, day.date)} className={'nav-link ' + (this.props.checkActiveDay(day.date, this.props.data.departDate) ? 'active' : '')}>
                          <div className="date-controls-day">{day.day}</div>
                          <div className="date-controls-date">{day.date}</div>
                        </a>
                      </li>
                    )
                  })}
                  <li className="date-controls-btn" onClick={this.props.nextWeek.bind(this, 1)}><span className="fa fa-caret-right"></span></li>
                </ul>
              </div>
              {this.props.data.loading &&
                <div className="fond">
                  <div className="contener_general">
                    <div className="contener_mixte"><div className="ballcolor ball_1">&nbsp;</div></div>
                    <div className="contener_mixte"><div className="ballcolor ball_2">&nbsp;</div></div>
                    <div className="contener_mixte"><div className="ballcolor ball_3">&nbsp;</div></div>
                    <div className="contener_mixte"><div className="ballcolor ball_4">&nbsp;</div></div>
                  </div>
                </div>
              }
              {this.props.data.trips.departingTrips.length === 0 && !this.props.data.loading &&
                <div className="fond">
                  <div className="no-trip-found">
                    <img src={noResultsImage} />
                    <div className="no-trip-head">No Remaining Schedules for the day</div>
                    <div className="no-trip-msg">Adjust the filters to find out what you are looking for.</div>
                  </div>
                </div>
              }
              {this.props.data.trips.departingTrips.length > 0 && <div className="col-md-12 p-0">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="pickup-details-row">
                        <strong>Pick up:</strong> <span className="pickup-details"><a href="https://tripperbus.com/locations/" target="_blank">{this.props.getStopPickup(this.props.data.departCity)}</a></span> <i className="fa fa-circle"></i> <strong>Drop off:</strong> <span className="pickup-details"><a href="http://tripperbus.com/locations/" target="_blank">{this.props.getStopPickup(this.props.data.arriveCity)}</a></span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <p><strong>Trip Duration</strong> {this.getDuration(this.props.getRequiredStop(this.props.data.trips.departingTrips[0].stops, this.props.data.departCity).time, this.props.getRequiredStop(this.props.data.trips.departingTrips[0].stops, this.props.data.arriveCity).time)}  (subject to traffic, weather and stop locations)<br/>
                      {this.props.getStopMetro(this.props.data.departCity)} <a href="https://tripperbus.com/locations/" target="_blank">(View Map)</a></p>
                    </div>

                    <div className="col-md-12 trip-selector p-0">
                      <div className="container-fluid">
                        <ReactTooltip />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
                        {this.props.data.trips.departingTrips.map((bus, index) => {
                          return (
                            <div key={index} className={'select-trip-row ' + (bus.bus_type.type === 'Express' ? 'has-update-row ' : '') + (bus.cancelled ? 'is-cancelled ' : '') + (bus.bus_type.type === 'Elite' ? 'elite-bus ' : '') + (bus.remainingSeats === 0 ? 'inactive ' : '') + (this.props.data.departTrip && this.props.data.departTrip.schedule_bus === bus.schedule_bus ? 'selected' : '')}>
                              <div className="row cardAccordian">
                                <div className="col-md-2 col-sm-5 col-5 select-trip-item deptarri-dept" data-toggle="collapse" data-target={'#collapse'+bus.schedule_bus}>
                                  <div className="deptarri-label">DEPARTURE</div>
                                  <div className="deptarri-time">{this.props.getTime(this.props.getRequiredStop(bus.stops, this.props.data.departCity).time)}</div>
                                  <div className="deptarri-loc">{this.props.getStopLabel(this.props.data.departCity)}</div>
                                </div>
                                <div className="col-md-1 col-sm-2 col-2 select-trip-item" data-toggle="collapse" data-target={'#collapse'+bus.schedule_bus}>
                                  {/* <Link to="">{bus.bus_type.name}</Link> */}
                                  <div className="tripArrow"></div>
                                </div>
                                <div className="col-md-2 col-sm-5 col-5 select-trip-item deptarri-arri" data-toggle="collapse" data-target={'#collapse'+bus.schedule_bus}>
                                  <div className="deptarri-label">APROX ARRIVAL</div>
                                  <div className="deptarri-time">{this.props.getTime(this.props.getRequiredStop(bus.stops, this.props.data.arriveCity).time)}</div>
                                  <div className="deptarri-loc">{this.props.getStopLabel(this.props.data.arriveCity)}</div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-6 select-trip-item" data-toggle="collapse" data-target={'#collapse'+bus.schedule_bus}>
                                  <div className="routeDetailsHeading cursor-pointer" data-toggle="collapse" data-target={'#collapse'+bus.schedule_bus}>Route Details</div>
                                  <ul className="route-details-menu list-inline">
                                    <li className={'route-detail-item list-inline-item ' + (bus.bus_type.hasMovies ? '' : 'not-available')} data-place="top" data-tip={'Movies ' + (bus.bus_type.hasWifi ? 'available' : 'not available')}>
                                      <Link to="#" className="route-detail-link"><img src={videoIcon} alt="" /></Link>
                                    </li>
                                    <li className={'route-detail-item list-inline-item ' + (bus.bus_type.hasWifi ? '' : 'not-available')} data-place="top" data-tip={'Wifi ' + (bus.bus_type.hasWifi ? 'available' : 'not available')}>
                                      <Link to="#" className="route-detail-link"><img src={wifiIcon} alt="" /></Link>
                                    </li>
                                    <li className={'route-detail-item list-inline-item ' + (bus.bus_type.hasPowerOutlets ? '' : 'not-available')} data-place="top" data-tip={'Power outlets ' + (bus.bus_type.hasPowerOutlets ? 'available' : 'not available')}>
                                      <Link to="#" className="route-detail-link"><img src={chargerIcon} alt="" /></Link>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-md-2 col-sm-6 col-6 select-trip-item deptarri-price" data-toggle="collapse" data-target={'#collapse'+bus.schedule_bus}>
                                  {bus.bus_type.type === 'Elite' && <div className="deptarri-elite" data-html={true} data-tip={this.getBusDescription(bus.bus_type.type)} data-place="top"><img height="20px" src={eliteIcon} /></div>}
                                  <div className="deptarri-price-amount">${bus.price}<span className="deptarri-price-label">/person</span></div>
                                  {bus.remainingSeats > 0 && bus.remainingSeats < this.props.data.seats ? <span className="deptarri-seats not-enough">Only {bus.remainingSeats} seat(s) available.</span> : ''}
                                </div>
                                <div className="col-md-2 select-trip-item">
                                  {!bus.cancelled && bus.remainingSeats > 0 && (!this.props.data.departTrip || (this.props.data.departTrip.schedule_bus !== bus.schedule_bus)) && <Link to="#" className={'btn btn-default' + (bus.remainingSeats < this.props.data.seats ? ' disabled' : '')} onClick={this.props.selectDepartTrip.bind(this, bus)}>Select</Link>}
                                  {bus.cancelled && <Link to="#" className="btn btn-default trip-cancelled">CANCELLED</Link>}
                                  {bus.remainingSeats <= 0 && <div className="selectTripBtn ml-auto">
                                    <a href="javascript:void(0)" className="btn btn-default sold-out">SOLD OUT</a>
                                  </div>}
                                  {this.props.data.departTrip && this.props.data.departTrip.schedule_bus === bus.schedule_bus && <div className="selectTripBtn">
                                    <a href="javascript:void(0)" className="btn btn-default selected" onClick={this.props.selectDepartTrip.bind(this, bus)}>Selected</a>
                                  </div>}
                                </div>
                              </div>
                              <div className="tripDetailsWrapper">
                                {(this.checkExpress(bus.stops) || bus.notes) && <div className="tripDetailFooter">
                                  {this.checkExpress(bus.stops) && <div className="tripExpress"><img src={expressIcon} alt="" /> <span>Express:</span> Skips Bethesda</div>}
                                  {bus.notes && <div className="tripUpdate"><img src={updateIcon} alt="" /> <span>Update:</span> {bus.notes}</div>}
                                </div>}
                                <div className="collapse" id={'collapse'+bus.schedule_bus}>
                                  <div className="col-md-12">
                                    <div className="tripDetailsRowWrap">
                                      {bus.stops.map((stop, i) => {
                                        return (<div key={i} className='tripDetailsRow active'>
                                          <div className="time">{moment(stop.time, 'HH:mm:ss').format('hh:mm a')}</div>
                                          <div className="address">{(stop.stop.city)}</div>
                                          <div className="address address2">{stop.stop.address}</div>
                                          <a className="viewMap" href="https://tripperbus.com/locations/" target="_blank">View Map</a>
                                        </div>)
                                      })}
                                    </div>
                                    <button className='btn btn-blue mobile-only close-details' data-toggle="collapse" data-target={'#collapse'+bus.schedule_bus}>Close</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>

                  </div>
                </div>
              </div>}

            </div>
          </div>
        </section>}

        {this.props.data.returnDate && this.props.data.departCity && this.props.data.arriveCity && this.props.data.departDate && this.props.data.returnDate && this.props.data.seats && <section className="trip-section">
          <div className="container departing-trip-wrap">
            <div className="row">

              {this.props.data.departCity && this.props.data.arriveCity && <div className="col-md-12 departing-trip-title step-b">
                <i className="fa fa-arrow-down"></i>
                 Returning Trip: <span className="trip-loc">{this.props.getStopLabel(this.props.data.arriveCity)}</span> to <span className="trip-loc">{this.props.getStopLabel(this.props.data.departCity)}</span>
              </div>}

              <div className="col-md-12 date-controls alt">
                
                <ul className="nav nav-pills nav-fill">
                  <li className="date-controls-btn return-back" onClick={this.props.previousWeek.bind(this, 2)}><span className="fa fa-caret-left"></span></li>
                  {this.props.data.futureReturns.map((day, index) => {
                    return (
                      <li  className={"nav-item " + (this.props.checkPastDate(day.date) ? "inactive" : "")} key={index}>
                        <a href="javascript:void(0)" onClick={this.props.checkFutureReturns.bind(this, day.date)} className={'nav-link ' + (this.props.checkActiveDay(day.date, this.props.data.returnDate) ? 'active' : '')}>
                          <div className="date-controls-day">{day.day}</div>
                          <div className="date-controls-date">{day.date}</div>
                        </a>
                      </li>
                    )
                  })}
                  <li className="date-controls-btn return-back" onClick={this.props.nextWeek.bind(this, 2)}><span className="fa fa-caret-right"></span></li>
                </ul>
              </div>

              {this.props.data.returnLoading &&
                <div className="fond">
                  <div className="contener_general">
                    <div className="contener_mixte"><div className="ballcolor ball_1">&nbsp;</div></div>
                    <div className="contener_mixte"><div className="ballcolor ball_2">&nbsp;</div></div>
                    <div className="contener_mixte"><div className="ballcolor ball_3">&nbsp;</div></div>
                    <div className="contener_mixte"><div className="ballcolor ball_4">&nbsp;</div></div>
                  </div>
                </div>
              }

              {this.props.data.trips.returningTrips.length === 0 && !this.props.data.returnLoading &&
                <div className="fond">
                  <div className="no-trip-found">
                    <img src={noResultsImage} />
                    <div className="no-trip-head">No Remaining Schedules for the day</div>
                    <div className="no-trip-msg">Try adjusting the filters to find results.</div>
                  </div>
                </div>
              }

              {this.props.data.trips.returningTrips.length>0 && <div className="col-md-12 p-0">
                <div className="container-fluid">
                  <div className="row">
                  <div className="col-md-12">
                      <div className="pickup-details-row">
                        <strong>Pick up:</strong> <span className="pickup-details"><a href="https://tripperbus.com/locations/" target="_blank">{this.props.getStopPickup(this.props.data.arriveCity)}</a></span> <i className="fa fa-circle"></i> <strong>Drop off:</strong> <span className="pickup-details"><a href="http://tripperbus.com/locations/" target="_blank">{this.props.getStopLabel(this.props.data.departCity)}</a></span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <p><strong>Trip Duration</strong> {this.getDuration(this.props.getRequiredStop(this.props.data.trips.returningTrips[0].stops, this.props.data.arriveCity).time, this.props.getRequiredStop(this.props.data.trips.returningTrips[0].stops, this.props.data.departCity).time)} (subject to traffic, weather and stop locations)<br/>
                      {this.props.getStopMetro(this.props.data.arriveCity)} <a href="https://tripperbus.com/locations/" target="_blank">(View Map)</a></p>
                    </div>

                    <div className="col-md-12 trip-selector p-0">
                      <div className="container-fluid">
                        {this.props.data.trips.returningTrips.map((bus, index) => {
                          return (
                            <div key={index} className={'row select-trip-row alt ' + (bus.bus_type.type === 'Express' ? 'has-update-row ' : '') + (bus.cancelled ? 'is-cancelled ' : '') + (bus.bus_type.type === 'Elite' ? 'elite-bus ' : '') + (bus.remainingSeats === 0 ? 'inactive ' : '') + (this.props.data.returnTrip && this.props.data.returnTrip.schedule_bus === bus.schedule_bus ? 'selected' : '')}>
                              <div className="row cardAccordian">
                                <div className="col-md-2 col-sm-5 col-5 select-trip-item deptarri-dept" data-toggle="collapse" data-target={'#collapse1'+bus.schedule_bus}>
                                  <div className="deptarri-label">DEPARTURE</div>
                                  <div className="deptarri-time">{this.props.getTime(this.props.getRequiredStop(bus.stops, this.props.data.arriveCity).time)}</div>
                                  <div className="deptarri-loc">{this.props.getStopLabel(this.props.data.arriveCity)}</div>
                                </div>
                                <div className="col-md-1 col-sm-2 col-2 select-trip-item" data-toggle="collapse" data-target={'#collapse1'+bus.schedule_bus}>
                                   {/* <Link to="">{bus.bus_type.name}</Link> */}
                                  <div className="tripArrow"></div>
                                </div>
                                <div className="col-md-2 col-sm-5 col-5 select-trip-item deptarri-arri" data-toggle="collapse" data-target={'#collapse1'+bus.schedule_bus}>
                                  <div className="deptarri-label">APROX ARRIVAL</div>
                                  <div className="deptarri-time">{this.props.getTime(this.props.getRequiredStop(bus.stops, this.props.data.departCity).time)}</div>
                                  <div className="deptarri-loc">{this.props.getStopLabel(this.props.data.departCity)}</div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-6 select-trip-item" data-toggle="collapse" data-target={'#collapse1'+bus.schedule_bus}>
                                  <div className="routeDetailsHeading cursor-pointer" data-toggle="collapse" data-target={'#collapse1'+bus.schedule_bus}>Route Details</div>
                                  <ul className="route-details-menu list-inline">
                                    <li className={'route-detail-item list-inline-item ' + (bus.bus_type.hasMovies ? '' : 'not-available')} data-place="top" data-tip={'Movies ' + (bus.bus_type.hasWifi ? 'available' : 'not available')}>
                                      <Link to="#" className="route-detail-link"><img src={videoIcon} alt="" /></Link>
                                    </li>
                                    <li className={'route-detail-item list-inline-item ' + (bus.bus_type.hasWifi ? '' : 'not-available')} data-place="top" data-tip={'Wifi ' + (bus.bus_type.hasWifi ? 'available' : 'not available')}>
                                      <Link to="#" className="route-detail-link"><img src={wifiIcon} alt="" /></Link>
                                    </li>
                                    <li className={'route-detail-item list-inline-item ' + (bus.bus_type.hasPowerOutlets ? '' : 'not-available')} data-place="top" data-tip={'Power outlets ' + (bus.bus_type.hasPowerOutlets ? 'available' : 'not available')}>
                                      <Link to="#" className="route-detail-link"><img src={chargerIcon} alt="" /></Link>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-md-2 col-sm-6 col-6 select-trip-item deptarri-price" data-toggle="collapse" data-target={'#collapse1'+bus.schedule_bus}>
                                  {bus.bus_type.type === 'Elite' && <span className="deptarri-elite" data-html={true} data-tip={this.getBusDescription(bus.bus_type.type)} data-place="top"><img height="20px" src={eliteIcon} /></span>}
                                  <div className="deptarri-price-amount">${bus.price}<span className="deptarri-price-label">/person</span></div><br/>
                                  {bus.remainingSeats > 0 && bus.remainingSeats < this.props.data.seats ? <span className="deptarri-seats not-enough">Only {bus.remainingSeats} seat(s) available.</span> : ''}
                                </div>
                                <div className="col-md-2 select-trip-item">
                                  {(!bus.cancelled && bus.remainingSeats > 0 && (!this.props.data.returnTrip || (this.props.data.returnTrip.schedule_bus !== bus.schedule_bus))) && <Link to="#" className={'btn btn-default' + (bus.remainingSeats < this.props.data.seats ? ' disabled' : '')} onClick={this.props.selectReturnTrip.bind(this, bus)}>Select</Link>}
                                  {bus.cancelled && <Link to="#" className="btn btn-default trip-cancelled">CANCELLED</Link>}
                                  {bus.remainingSeats <= 0 && <div className="selectTripBtn ml-auto">
                                    <a href="javascript:void(0)" className="btn btn-default sold-out">SOLD OUT</a>
                                  </div>}
                                  {this.props.data.returnTrip && this.props.data.returnTrip.schedule_bus === bus.schedule_bus && <div className="selectTripBtn">
                                    <a href="javascript:void(0)" className="btn btn-default selected" onClick={this.props.selectReturnTrip.bind(this, bus)}>Selected</a>
                                  </div>}
                                </div>
                              </div>
                              <div className="tripDetailsWrapper">
                                {(this.checkExpress(bus.stops) || bus.notes) && <div className="tripDetailFooter">
                                  {this.checkExpress(bus.stops) && <div className="tripExpress"><img src={expressIcon} alt="" /> <span>Express:</span> Skips Bethesda</div>}
                                  {bus.notes && <div className="tripUpdate"><img src={updateIcon} alt="" /> <span>Update:</span> {bus.notes}</div>}
                                </div>}
                                <div className="row collapse" id={'collapse1'+bus.schedule_bus}>
                                  <div className="col-md-12">
                                    <div className="tripDetailsRowWrap">
                                      {bus.stops.map((stop, i) => {
                                        return (<div key={i} className='tripDetailsRow'>
                                          <div className="time">{moment(stop.time, 'HH:mm:ss').format('hh:mm a')}</div>
                                          <div className="address">{stop.stop.city}</div>
                                          <div className="address">{stop.stop.address}</div>
                                          <a className="viewMap" href="https://tripperbus.com/locations/" target="_blank">View Map</a>
                                        </div>)
                                      })}
                                    </div>
                                    <button className='btn btn-blue mobile-only close-details' data-toggle="collapse" data-target={'#collapse'+bus.schedule_bus}>Close</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>

                  </div>
                </div>
              </div>}

            </div>
          </div>
        </section>}

        {((!this.props.data.returnDate && this.props.data.trips.departingTrips.length > 0) || (this.props.data.returnDate && this.props.data.trips.departingTrips.length > 0 && this.props.data.trips.returningTrips.length > 0)) && <div className="container getMyTickets">
            <span>You won't be charged until the next step.</span><button disabled={(!this.props.data.returnDate && !this.props.data.departTrip) || (this.props.data.returnDate && (!this.props.data.departTrip || !this.props.data.returnTrip)) || this.props.data.loading} onClick={this.props.getTickets.bind(this)} className='btn btn-blue' type='button'>Get My Tickets</button>{this.props.data.wrongTime && <div className="error">{this.props.data.wrongTime}</div>}
        </div>}

        {(!this.props.data.departCity || !this.props.data.arriveCity || !this.props.data.departDate || !this.props.data.seats) &&
          <div className="fond">
            <div className="no-trip-found">
              <img src={noResultsImage} />
              <div className="no-trip-head">No Remaining Schedules for the day</div>
              <div className="no-trip-msg">Try adjusting the filters to find results.</div>
            </div>
          </div>
        }
        {/* {(!this.props.data.departCity || !this.props.data.arriveCity || !this.props.data.departDate || !this.props.data.seats) &&
          <div className="covid-wrap">
            <div className="covid-head">COVID-19 NOTICE</div>
            <p>
              Due to continuing spread of COVID-19 we have decided to temporarily suspend our daily schedules. We plan to resume regular operations on June 18th. Schedules for future travel are available online for booking. Your safety is our priority. We have a responsibility to our passengers, employees and community to do our part to prevent the spread of COVID-19. We appreciate your support and understanding and look forward to having you on board soon. We will be issuing a credit for future use in your Tripperbus account.
            </p>
          </div>
        } */}
      </div>
  	)
  }
}

export default Home;

