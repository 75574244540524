// home.js
import React from 'react';
import ScheduleService from '../../services/schedule';
import ScheduleStore from '../../stores/ScheduleStore';
import moment from 'moment';
import _ from 'lodash';
import Trips from './trips';
import TripFilters from './tripFilters';
import ConfirmTrip from './confirmTrip';
import config from '../../config';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.onSchedulesChange = this.onSchedulesChange.bind(this);
    this.onReturnSchedulesChange = this.onReturnSchedulesChange.bind(this);
    this.state = {
      loading: false,
      returnLoading: false,
      departCity: '',
      arriveCity: '',
      departDate: moment(),
      returnDate: null,
      itemsInSlider: 5,
      seats: '1',
      currentStep: 2,
      today: moment().startOf('day'),
      trips: {
        departingTrips: [],
        returningTrips: []
      },
      futureDeparts: [],
      futureReturns: [],
      departTrip: null,
      returnTrip: null,
      cities: config.cities,
      arriveCities: config.cities,
      depart_stop: null,
      depart_stop1: null,
      arrive_stop: null,
      arrive_stop1: null,
      wrongTime: false
    };
    this.days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  }

  handleUserInput(e){
    if(e.target.name === 'departCity') {
      this.setState({departCity: e.target.value, arriveCity: ''});
      console.log(e.target.value)
      let arriveCities = [];
      if(e.target.value === 'new york') {
        config.cities.map((item) => {
          if(item.value !== e.target.value) {
            arriveCities.push(item);
          }
          return item;
        });
      }
      else {
        config.cities.map((item) => {
          if(item.value === 'new york') {
            arriveCities.push(item);
          }
          return item;
        });
      }
      this.setState({arriveCities: arriveCities});
      return;
    }
    if(e.target.name === 'arriveCity' && this.state.departCity && (e.target.value === this.state.departCity)) {
      return;
    }
    this.setState({[e.target.name]: e.target.value}, function(){
      if(this.state.departCity && this.state.arriveCity && this.state.departDate && this.state.returnDate && this.state.seats) {
        this.createDepartFutureArray(this.state.departDate);
        this.createReturnFutureArray(this.state.returnDate);
        this.getSchedules();
      }
      else if(this.state.departCity && this.state.arriveCity && this.state.departDate && this.state.seats) {
        this.createDepartFutureArray(this.state.departDate);
        this.getSchedules();
      }
    });
  }

  getRequiredStop(stops, city) {
    return _.find(stops, (stop) => {
      return stop.stop.city === city;
    });
  }

  selectDepartTrip(bus, e) {
    e.preventDefault();
    if(bus.remainingSeats < this.state.seats) {
      return;
    }
    if(this.state.departTrip && this.state.departTrip.schedule_bus === bus.schedule_bus) {
      this.setState({departTrip: null});
      return;
    }
    else {
      this.setState({departTrip: bus, depart_stop: this.getRequiredStop(bus.stops, this.state.departCity), depart_time: this.getRequiredStop(bus.stops, this.state.departCity).time, arrive_stop: this.getRequiredStop(bus.stops, this.state.arriveCity), arrive_time: this.getRequiredStop(bus.stops, this.state.arriveCity).time});
    }
  }

  selectReturnTrip(bus, e) {
    e.preventDefault();
    if(bus.remainingSeats < this.state.seats) {
      return;
    }
    if(this.state.returnTrip && this.state.returnTrip.schedule_bus === bus.schedule_bus) {
      this.setState({returnTrip: null});
      return;
    }
    else {
      this.setState({returnTrip: bus, depart_stop1: this.getRequiredStop(bus.stops, this.state.arriveCity), depart_time1: this.getRequiredStop(bus.stops, this.state.arriveCity).time, arrive_stop1: this.getRequiredStop(bus.stops, this.state.departCity), arrive_time1: this.getRequiredStop(bus.stops, this.state.departCity).time});
    }
  }

  sortByTime(data) {
    for(let i = 0; i < data.length; i++) {
      if(data[i].stops.length === 3) {
        if(data[i].stops[0].type === 'DO') {
          data[i].stops = [
            data[i].stops[1],
            data[i].stops[2],
            data[i].stops[0]
          ];
        }
        // check again now if second stop is also drop off (reading 0 index because 1 has been shifted to 0 above)
        if(data[i].stops[0].type === 'DO') {
          data[i].stops = [
            data[i].stops[1],
            data[i].stops[2],
            data[i].stops[0]
          ];
        }
      }
      else if(data[i].stops.length === 2) {
        if(data[i].stops[0].type === 'DO') {
          data[i].stops = [
            data[i].stops[1],
            data[i].stops[0]
          ];
        }
      }
    }
    return _.sortBy(data, item => moment(item.stops[0].time, 'HH:mm:ss'));
  }

  filterUpcoming(data) {
    return _.filter(data, (item) => {
      return moment(moment(this.state.departDate).format('YYYY-MM-DD') + ' ' + this.getRequiredStop(item.stops, this.state.departCity).time, 'YYYY-MM-DD HH:mm:ss').diff(moment(), 'minutes') > 5; 
    });
  }

  filterUpcomingReturn(data) {
    return _.filter(data, (item) => {
      return moment(moment(this.state.returnDate).format('YYYY-MM-DD') + ' ' + this.getRequiredStop(item.stops, this.state.arriveCity).time, 'YYYY-MM-DD HH:mm:ss').diff(moment(), 'minutes') > 5; 
    });
  }

  onSchedulesChange(){
    let results = ScheduleStore.getSchedules();
    console.log(results)
    let trips = {
      departingTrips: [],
      returningTrips: []
    };
    if(results.length > 0) {
      trips.departingTrips = this.sortByTime(this.filterUpcoming(results));
    }
    if(this.state.returnDate) {
      let results1 = ScheduleStore.getReturnSchedules();
      if(results1.length > 0) {
        trips.returningTrips = this.sortByTime(this.filterUpcomingReturn(results1));
      }
    }
    this.setState({trips: trips, loading: false});
    if(this.state.currentStep !== 3) {
      this.setState({departTrip: null, returnTrip: null});
    }
  }

  onReturnSchedulesChange(){
    let results = ScheduleStore.getSchedules();
    let trips = {
      departingTrips: [],
      returningTrips: []
    };
    if(results.length > 0) {
      trips.departingTrips = this.sortByTime(this.filterUpcoming(results));
    }
    if(this.state.returnDate) {
      let results1 = ScheduleStore.getReturnSchedules();
      if(results1.length > 0) {
        trips.returningTrips = this.sortByTime(this.filterUpcomingReturn(results1));
      }
    }
    this.setState({trips: trips, returnLoading: false});
    if(this.state.currentStep !== 3) {
      this.setState({departTrip: null, returnTrip: null});
    }
  }

  getSchedules() {
    if(this.state.departCity === this.state.arriveCity) {
      return;
    }
    if(this.state.departCity === 'arlington' || this.state.departCity === 'bethesda') {
      if(this.state.arriveCity !== 'new york') {
        return;
      }
    }
    this.setState({wrongTime: false});
    this.setState({trips: {
      departingTrips: [],
      returningTrips: []
    }, loading: true});
    if(this.state.returnDate) {
      this.setState({returnLoading: true});
    }
    let criteria = {
      departCity: this.state.departCity,
      arriveCity: this.state.arriveCity,
      departDate: moment(this.state.departDate).format('YYYY-MM-DD'),
      seats: parseInt(this.state.seats)
    };
    ScheduleService.get(criteria)
    .then(() => {

    })
    .catch((err) => {
      console.log(err)
      this.setState({loading: false});
    });
    if(this.state.returnDate) {
      let criteriaReturn = {
        departCity: this.state.arriveCity,
        arriveCity: this.state.departCity,
        departDate: moment(this.state.returnDate).format('YYYY-MM-DD'),
        seats: parseInt(this.state.seats)
      };
      ScheduleService.get(criteriaReturn, true)
      .then(() => {

      })
      .catch((err) => {
        console.log(err)
        this.setState({returnLoading: false});
      });
    }
  }

  createDepartFutureArray(date, addSub) {
    let dayNumber = new Date(date).getDay();
    let departDay = this.days[dayNumber];
    console.log(departDay)
    if(this.state.futureDeparts.length > 0 && !date) {
      let futureDeparts = this.state.futureDeparts;
      for(let i = 0; i < futureDeparts.length; i++) {
        if(addSub === 'add') {
          futureDeparts[i].date = moment(futureDeparts[i].date, 'M/D/YY').add(1, 'day').format('M/D/YY');
          futureDeparts[i].day = this.days[new Date(moment(futureDeparts[i].date, 'M/D/YY')).getDay()];
        }
        else {
          futureDeparts[i].date = moment(futureDeparts[i].date, 'M/D/YY').subtract(1, 'day').format('M/D/YY');
          futureDeparts[i].day = this.days[new Date(moment(futureDeparts[i].date, 'M/D/YY')).getDay()]; 
        }
      }
      this.setState({futureDeparts: futureDeparts});
    }
    else if(this.state.itemsInSlider === 5) {
      let futureDeparts = [
        {
          date: moment(date).subtract(2, 'day').format('M/D/YY'),
          day: this.days[new Date(moment(date).subtract(2, 'day')).getDay()]
        },
        {
          date: moment(date).subtract(1, 'day').format('M/D/YY'),
          day: this.days[new Date(moment(date).subtract(1, 'day')).getDay()]
        },
        {
          date: moment(date).format('M/D/YY'),
          day: departDay
        },
        {
          date: moment(date).add(1, 'day').format('M/D/YY'),
          day: this.days[new Date(moment(date).add(1, 'day')).getDay()]
        },
        {
          date: moment(date).add(2, 'day').format('M/D/YY'),
          day: this.days[new Date(moment(date).add(2, 'day')).getDay()]
        }
      ];
      this.setState({futureDeparts: futureDeparts});
    }
    else {
      let futureDeparts = [
        {
          date: moment(date).subtract(1, 'day').format('M/D/YY'),
          day: this.days[new Date(moment(date).subtract(1, 'day')).getDay()]
        },
        {
          date: moment(date).format('M/D/YY'),
          day: departDay
        },
        {
          date: moment(date).add(1, 'day').format('M/D/YY'),
          day: this.days[new Date(moment(date).add(1, 'day')).getDay()]
        }
      ];
      this.setState({futureDeparts: futureDeparts});
    }
  }

  createReturnFutureArray(date, addSub) {
    let dayNumber = new Date(date).getDay();
    let returnDay = this.days[dayNumber];
    if(this.state.futureReturns.length > 0 && !date) {
      let futureReturns = this.state.futureReturns;
      for(let i = 0; i < futureReturns.length; i++) {
        if(addSub === 'add') {
          futureReturns[i].date = moment(futureReturns[i].date, 'M/D/YY').add(1, 'day').format('M/D/YY');
          futureReturns[i].day = this.days[new Date(moment(futureReturns[i].date, 'M/D/YY')).getDay()];
        }
        else {
          futureReturns[i].date = moment(futureReturns[i].date, 'M/D/YY').subtract(1, 'day').format('M/D/YY');
          futureReturns[i].day = this.days[new Date(moment(futureReturns[i].date, 'M/D/YY')).getDay()]; 
        }
      }
      this.setState({futureReturns: futureReturns});
    }
    else if(this.state.itemsInSlider === 5) {
      let futureReturns = [
        {
          date: moment(date).subtract(2, 'day').format('M/D/YY'),
          day: this.days[new Date(moment(date).subtract(2, 'day')).getDay()]
        },
        {
          date: moment(date).subtract(1, 'day').format('M/D/YY'),
          day: this.days[new Date(moment(date).subtract(1, 'day')).getDay()]
        },
        {
          date: moment(date).format('M/D/YY'),
          day: returnDay
        },
        {
          date: moment(date).add(1, 'day').format('M/D/YY'),
          day: this.days[new Date(moment(date).add(1, 'day')).getDay()]
        },
        {
          date: moment(date).add(2, 'day').format('M/D/YY'),
          day: this.days[new Date(moment(date).add(2, 'day')).getDay()]
        }
      ];
      this.setState({futureReturns: futureReturns});
    }
    else {
      let futureReturns = [
        {
          date: moment(date).subtract(1, 'day').format('M/D/YY'),
          day: this.days[new Date(moment(date).subtract(1, 'day')).getDay()]
        },
        {
          date: moment(date).format('M/D/YY'),
          day: returnDay
        },
        {
          date: moment(date).add(1, 'day').format('M/D/YY'),
          day: this.days[new Date(moment(date).add(1, 'day')).getDay()]
        }
      ];
      this.setState({futureReturns: futureReturns});
    }
  }

  handleDepartDateChange (value, e) {
    e.preventDefault();
    if(value.startOf('day').diff(this.state.today, 'days') < 0) {
      return;
    }
    if(this.state.returnDate) {
      if(this.state.returnDate.diff(value.startOf('day'), 'days') < 0) {
        this.setState({departDate: value.startOf('day'), returnDate: null}, function(){
          this.createDepartFutureArray(this.state.departDate);
          if(this.state.departCity && this.state.arriveCity && this.state.departDate && this.state.seats) {
            this.getSchedules();
          }
        });
      }
      else {
        this.setState({departDate: value.startOf('day')}, function(){
          this.createDepartFutureArray(this.state.departDate);
          if(this.state.departCity && this.state.arriveCity && this.state.departDate && this.state.returnDate && this.state.seats) {
            this.getSchedules();
          }
        });
      }
    }
    else {
      this.setState({departDate: value.startOf('day')}, function(){
        this.createDepartFutureArray(this.state.departDate);
        if(this.state.departCity && this.state.arriveCity && this.state.departDate && this.state.seats) {
          this.getSchedules();
        }
      });
    }
  }

  handleReturnDateChange (value, e) {
    e.preventDefault();
    if(value.startOf('day').diff(this.state.departDate, 'days') < 0) {
      return;
    }
    this.setState({returnDate: value.startOf('day')}, function(){
      this.createDepartFutureArray(this.state.departDate);
      this.createReturnFutureArray(this.state.returnDate);
      if(this.state.departCity && this.state.arriveCity && this.state.departDate && this.state.returnDate && this.state.seats) {
        this.getSchedules();
      }
    });
  }

  removeReturn () {
    this.setState({returnDate: null, futureReturns: []}, function(){
      if(this.state.departCity && this.state.arriveCity && this.state.departDate && this.state.seats) {
        this.getSchedules();
      }
    });
  }

  checkFutureDepart(date) {
    if(this.checkPastDate(date)) {
      return;
    }
    this.setState({departDate: moment(date, 'M/D/YY')}, () => {
      this.getSchedules();
    });
  }

  checkFutureReturns(date) {
    if(this.checkPastDate(date)) {
      return;
    }
    this.setState({returnDate: moment(date, 'M/D/YY')}, () => {
      this.getSchedules();
    });
  }

  getStopLabel(stop) {
    console.log(this.state.cities);
    return _.find(this.state.cities, {value: stop}).label;
  }

  getStopLabelWithZip(stop) {
    let label = _.find(this.state.cities, {value: stop});
    return label.label;
  }

  getStopMetro(stop) {
    return _.find(this.state.cities, {value: stop}).metro;
  }

  getStopPickup(stop) {
    console.log(this.state.cities);
    return _.find(this.state.cities, {value: stop}).address;
  }

  getTime(time) {
    return moment(time, "HH:mm:ss").format("hh:mm A");
  }

  checkActiveDay(day, currentDay) {
    return (new Date(day).getDate() === new Date(currentDay).getDate());
  }

  componentDidMount() {
    window.$("html, body").animate({ scrollTop: 0 }, 'fast');
    ScheduleStore.addChangeListener(this.onSchedulesChange);
    ScheduleStore.addReturnListener(this.onReturnSchedulesChange);
    let width = window.$(window).width();
    console.log(width)
    if(width <= 1023) {
      this.setState({itemsInSlider: 3});
    }
    else {
      this.setState({itemsInSlider: 5});
    }
    let obj = {};
    if(this.props.location.query.departCity) {
      obj.departCity = this.props.location.query.departCity.replace('+', ' ');
    }
    if(this.props.location.query.arriveCity) {
      obj.arriveCity = this.props.location.query.arriveCity.replace('+', ' ');
    }
    if(this.props.location.query.departDate) {
      obj.departDate = moment(this.props.location.query.departDate, 'YYYY-MM-DD');
      if(this.props.location.query.returnDate) {
        obj.returnDate = moment(this.props.location.query.returnDate, 'YYYY-MM-DD');
      }
    }
    if(this.props.location.query.seats) {
      obj.seats = parseInt(this.props.location.query.seats);
    }
    if(Object.keys(obj).length > 0) {
      this.setState(obj, () => {
        if(this.state.departCity && this.state.arriveCity && this.state.departDate && this.state.returnDate && this.state.seats) {
          this.createDepartFutureArray(this.state.departDate);
          this.createReturnFutureArray(this.state.returnDate);
          this.getSchedules();
        }
        else if(this.state.departCity && this.state.arriveCity && this.state.departDate && this.state.seats) {
          this.createDepartFutureArray(this.state.departDate);
          this.getSchedules();
        }
      });
    }
    window.$(window).resize(() => {
      let width = window.$(window).width();
      console.log(width)
      if(width <= 1023) {
        if(this.state.itemsInSlider !== 3) {
          this.setState({itemsInSlider: 3}, () => {
            if(this.state.futureDeparts.length > 0) {
              this.createDepartFutureArray(moment(this.state.futureDeparts[2].date, 'M/D/YY'));
            }
            if(this.state.futureReturns.length > 0) {
              this.createReturnFutureArray(moment(this.state.futureReturns[2].date, 'M/D/YY'));
            }
          });
        }
      }
      else {
        if(this.state.itemsInSlider !== 5) {
          this.setState({itemsInSlider: 5}, () => {
            if(this.state.futureDeparts.length > 0) {
              this.createDepartFutureArray(moment(this.state.futureDeparts[1].date, 'M/D/YY'));
            }
            if(this.state.futureReturns.length > 0) {
              this.createReturnFutureArray(moment(this.state.futureReturns[1].date, 'M/D/YY'));
            }
          });
        }
      }
    });
  }

  componentWillUnmount() {
    ScheduleStore.removeChangeListener(this.onSchedulesChange);
    ScheduleStore.removeReturnListener(this.onReturnSchedulesChange);
  }

  checkActiveStep(step) {
    return this.state.currentStep === step;
  }

  getTickets() {
    this.setState({wrongTime: false});
    if(this.state.returnTrip) {
      console.log(this.state.returnDate.startOf('day').diff(this.state.departDate.startOf('day'), 'days'))
      if(this.state.returnDate.startOf('day').diff(this.state.departDate.startOf('day'), 'days') < 0) {
        this.setState({wrongTime: 'Return must come after departure date.'});
        return;
      }
      if(this.state.returnDate.startOf('day').diff(this.state.departDate.startOf('day'), 'days') === 0) {
        if(moment(this.state.depart_stop1.time, 'HH:mm:ss').diff(moment(this.state.arrive_stop.time, 'HH:mm:ss'), 'minutes') < 0) {
          this.setState({wrongTime: 'Arrival time of depart trip must be less than departure time of return trip.'});
          return;
        }
      }
    }
    this.setState({currentStep: 3});
  }

  editTrip() {
    this.setState({currentStep: 2});
  }

  checkPastDate(date) {
    return (moment().diff(moment(date, 'M/D/YY'), 'days') > 0);
  }

  nextWeek(num) {
    if(num === 1) {
      this.createDepartFutureArray(null, 'add');
    }
    else if(num === 2) {
      this.createReturnFutureArray(null, 'add');
    }
  }

  previousWeek(num) {
    if(num === 1) {
      this.createDepartFutureArray(null, 'subtract');
    }
    else if(num === 2) {
      this.createReturnFutureArray(null, 'subtract');
    }
  }

  render() {
  	return (
      <div>
        <header className="masthead text-center">
          &nbsp;
        </header>
        <section id="travel-form-region" className="travel-form">
          <div className="container">
            <div className="row mlr-0">
              <div className="card travel-form-wrapper">
                <div className="content content-full-width">
                  <div className="container-fluid tabs-wrapper">
                    <div className="tabContent">
                      {this.state.currentStep === 2 && <div className="travel-form-title">Select your travel times</div>}
                      {this.state.currentStep === 3 && <div className="travel-form-title">Booking Summary</div>}
                    </div>
                    <div className="form-steps">
                      <div className="row">
                        <div className="col-4"><div className={'timeline-digit first ' + (this.checkActiveStep(1) ? 'active-step' : '')}>1</div><div className="timeline-label">Plan Trip</div></div>
                        <div className="col-4"><div className={'timeline-digit second current-pos ' + (this.checkActiveStep(2) ? 'active-step' : '') + (this.checkActiveStep(3) ? 'completed' : '')}>2</div><div className="timeline-label">Choose Times</div></div>
                        <div className="col-4"><div className={'timeline-digit third ' + (this.checkActiveStep(3) ? 'active-step' : '')}>3</div><div className="timeline-label">Confirm</div></div>
                      </div>
                    </div>
                  </div>
                  {this.props.location.query.reservationNumber && <div className="editing-existing"><i className="fa fa-exclamation-circle"></i>Now editing the existing reservation <strong>#{config.formatReservationNumber(this.props.location.query.reservationNumber)}</strong></div>}
                  {this.state.currentStep === 2 && <div className={'tab-content' + (this.props.location.query.reservationNumber ? ' reduced-shadow' : '')} >
                    <div id="round-trip" className="tab-pane fade in active show">
                      <TripFilters
                        data = {{
                          departCity: this.state.departCity,
                          departDate: this.state.departDate,
                          arriveCity: this.state.arriveCity,
                          returnDate: this.state.returnDate,
                          seats: this.state.seats,
                          arriveCities: this.state.arriveCities
                        }}
                        handleReturnDateChange = {this.handleReturnDateChange.bind(this)}
                        handleDepartDateChange = {this.handleDepartDateChange.bind(this)}
                        handleUserInput = {this.handleUserInput.bind(this)}
                        removeReturn = {this.removeReturn.bind(this)}
                      />
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </section>
        {this.state.currentStep === 2 && <Trips
          data = {{
            departCity: this.state.departCity,
            departDate: this.state.departDate,
            arriveCity: this.state.arriveCity,
            returnDate: this.state.returnDate,
            seats: this.state.seats,
            trips: this.state.trips,
            futureReturns: this.state.futureReturns,
            futureDeparts: this.state.futureDeparts,
            departTrip: this.state.departTrip,
            returnTrip: this.state.returnTrip,
            loading: this.state.loading,
            returnLoading: this.state.returnLoading,
            wrongTime: this.state.wrongTime,
            depart_stop: this.state.depart_stop || (this.state.departTrip && this.getRequiredStop(this.state.departTrip.stops, this.state.departCity)),
            depart_time: this.state.depart_time || (this.state.departTrip && this.getRequiredStop(this.state.departTrip.stops, this.state.departCity).time),
            depart_stop1: this.state.depart_stop1 || (this.state.returnTrip && this.getRequiredStop(this.state.returnTrip.stops, this.state.arriveCity)),
            depart_time1: this.state.depart_time1 || (this.state.returnTrip && this.getRequiredStop(this.state.returnTrip.stops, this.state.arriveCity).time),
            arrive_stop: this.state.arrive_stop || (this.state.departTrip && this.getRequiredStop(this.state.departTrip.stops, this.state.arriveCity)),
            arrive_stop1: this.state.arrive_stop1 || (this.state.returnTrip && this.getRequiredStop(this.state.returnTrip.stops, this.state.departCity))
          }}
          getStopLabel = {this.getStopLabel.bind(this)}
          getStopPickup = {this.getStopPickup.bind(this)}
          getStopMetro = {this.getStopMetro.bind(this)}
          checkFutureDepart = {this.checkFutureDepart.bind(this)}
          checkActiveDay = {this.checkActiveDay.bind(this)}
          getTime = {this.getTime.bind(this)}
          selectDepartTrip = {this.selectDepartTrip.bind(this)}
          selectReturnTrip = {this.selectReturnTrip.bind(this)}
          checkFutureReturns = {this.checkFutureReturns.bind(this)}
          getTickets = {this.getTickets.bind(this)}
          checkPastDate = {this.checkPastDate.bind(this)}
          nextWeek = {this.nextWeek.bind(this)}
          previousWeek = {this.previousWeek.bind(this)}
          getRequiredStop = {this.getRequiredStop.bind(this)}
        />}

        {this.state.currentStep === 3 && <ConfirmTrip
          data = {{
            departCity: this.state.departCity,
            departDate: this.state.departDate,
            arriveCity: this.state.arriveCity,
            returnDate: this.state.returnDate,
            seats: this.state.seats,
            departTrip: this.state.departTrip,
            returnTrip: this.state.returnTrip,
            depart_stop: this.state.depart_stop || (this.state.departTrip && this.getRequiredStop(this.state.departTrip.stops, this.state.departCity)),
            depart_time: this.state.depart_time || (this.state.departTrip && this.getRequiredStop(this.state.departTrip.stops, this.state.departCity).time),
            depart_stop1: this.state.depart_stop1 || (this.state.returnTrip && this.getRequiredStop(this.state.returnTrip.stops, this.state.arriveCity)),
            depart_time1: this.state.depart_time1 || (this.state.returnTrip && this.getRequiredStop(this.state.returnTrip.stops, this.state.arriveCity).time),
            arrive_stop: this.state.arrive_stop || (this.state.departTrip && this.getRequiredStop(this.state.departTrip.stops, this.state.arriveCity)),
            arrive_stop1: this.state.arrive_stop1 || (this.state.returnTrip && this.getRequiredStop(this.state.returnTrip.stops, this.state.departCity)),
            reservationNumber: this.props.location.query.reservationNumber,
            busType: this.props.location.query.busType,
            oldSeats: this.props.location.query.seats,
            amount: parseInt(this.props.location.query.amount || 0),
            points: parseInt(this.props.location.query.points || 0),
            oldPrice: parseInt(this.props.location.query.price || 0)
          }}
          editTrip = {this.editTrip.bind(this)}
          getStopLabel = {this.getStopLabel.bind(this)}
          getStopLabelWithZip = {this.getStopLabelWithZip.bind(this)}
          getRequiredStop = {this.getRequiredStop.bind(this)}
        />}

        <section id="name-of-section" className="section-name">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
              </div>
            </div>
          </div>
        </section>
    	  <div className="scroll-to-top d-lg-none position-fixed ">
          <a className="js-scroll-trigger d-block text-center text-white rounded" href="#page-top">
            <i className="fa fa-chevron-up"></i>
          </a>
        </div>
      </div>
  	)
  }
}

export default Home;