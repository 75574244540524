// application.js
import request from 'superagent';
import { setReservation, setHistory, setUpcoming } from '../actions/ReservationActions.js';
import { setUser } from '../actions/UserActions.js';
import config from '../config';

class ReservationService {
  getByEmail(query) {
    return new Promise((resolve, reject) => {
      request.get(config.apiUrl+'reservations/search/email')
      .query(query)
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer '+localStorage.getItem('token'))
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(setReservation(response.body));
        }
        else if(error){
          reject(error);
        }
        else{
          reject(response.body);
        }
      });
    })
  }

  getReservationHistory() {
    return new Promise((resolve, reject) => {
      request.get(config.apiUrl+'reservations/user/history')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer '+localStorage.getItem('token'))
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(setHistory(response.body));
        }
        else if(error){
          reject(error);
        }
        else{
          reject(response.body);
        }
      });
    })
  }

  getUpcomingReservations(qery) {
    return new Promise((resolve, reject) => {
      request.get(config.apiUrl+'reservations/user/upcoming')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer '+localStorage.getItem('token'))
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(setUpcoming(response.body));
        }
        else if(error){
          reject(error);
        }
        else{
          reject(response.body);
        }
      });
    })
  }

  cancelSeats(reservationNumber, email, count, reason) {
    if(localStorage.getItem('token')) {
      return new Promise((resolve, reject) => {
        request.post(config.apiUrl+'reservations/cancel-and-refund/' + reservationNumber + '/' + email + '/' + count)
        .send({reason: reason})
        .set('Accept', 'application/json')
        .set('Authorization', 'Bearer '+localStorage.getItem('token'))
        .end((error, response) => {
          if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
            if(response.body.token) {
              setUser(response.body.token);
              resolve(response.body);
            }
            else{
              resolve(response.body);
            }
          }
          else if(error){
            reject(error);
          }
          else{
            reject(response.body);
          }
        });
      })
    }
    else {
      return new Promise((resolve, reject) => {
        request.post(config.apiUrl+'reservations/cancel/' + reservationNumber + '/' + email + '/' + count)
        .send({reason: reason})
        .set('Accept', 'application/json')
        .end((error, response) => {
          if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
            if(response.body.token) {
              setUser(response.body.token);
              resolve(response.body);
            }
            else{
              resolve(response.body);
            }
          }
          else if(error){
            reject(error);
          }
          else{
            reject(response.body);
          }
        });
      })
    }
  }

  mailTicket(number) {
    return new Promise((resolve, reject) => {
      request.get(config.apiUrl+'reservations/mail/confirm/' + number)
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer '+localStorage.getItem('token'))
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(response.body);
        }
        else if(error){
          reject(error);
        }
        else{
          reject(response.body);
        }
      });
    })
  }

  download(number) {
    return new Promise((resolve, reject) => {
      request.get(config.apiUrl+'reservations/download/' + number)
      .responseType('arraybuffer')
      .set('Accept', 'application/pdf')
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(response);
        }
        else if(error){
          reject(error);
        }
        else{
          reject(response.body);
        }
      });
    })
  }

}

export default new ReservationService();