// auth_service.js
import request from 'superagent/lib/client';
import { registerUser, setUser, setTopbar } from '../actions/UserActions';
import config from '../config';

class UserService {
  register(data) {
    return new Promise((resolve, reject) => {
      request.post(config.apiUrl+'users')
      .send(data)
      .set('Accept', 'application/json')
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(registerUser(response.body.token));
        }
        else if(error){
          reject('Error: '+JSON.stringify(error));
        }
        else{
          reject(response.body);
        }
      });
    })
  }

  getCreditsAndVouchers() {
    return new Promise((resolve, reject) => {
      request.get(config.apiUrl+'users/credits-and-vouchers')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer '+localStorage.getItem('token'))
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(setUser(response.body.token));
        }
        else if(error){
          reject('Error: '+JSON.stringify(error));
        }
        else{
          reject(response.body);
        }
      });
    })
  }

  update(id, data) {
    return new Promise((resolve, reject) => {
      request.put(config.apiUrl+'users/'+id)
      .send(data)
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer '+localStorage.getItem('token'))
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(setUser(response.body.token));
        }
        else if(error){
          reject(error);
        }
        else{
          reject(response.body);
        }
      });
    })
  }

  getTopbar() {
    return new Promise((resolve, reject) => {
      request.get(config.apiUrl+'topbar')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer '+localStorage.getItem('token'))
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(setTopbar(response.body));
        }
        else if(error){
          reject('Error: '+JSON.stringify(error));
        }
        else{
          reject(response.body);
        }
      });
    })
  }

  sendResetLink(email) {
    return new Promise((resolve, reject) => {
      request.get(config.apiUrl+'users/reset-link/'+email)
      .set('Accept', 'application/json')
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve('Success');
        }
        else if(error){
          reject(error);
        }
        else{
          reject('Something went wrong');
        }
      });
    });
  }

  getEarnedPoints(date, bus) {
    return new Promise((resolve, reject) => {
      request.get(config.apiUrl+'vouchers/earned/'+date+'/'+bus)
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer '+localStorage.getItem('token'))
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(response.body);
        }
        else if(error){
          reject(error);
        }
        else{
          reject(response.body);
        }
      });
    })
  }
}

export default new UserService();
