// UserActions.js
import Dispatcher from '../dispatcher/Dispatcher';

export function setSchedules(schedules) {
  Dispatcher.handleAction({
    type: 'SET_SCHEDULES',
    data: schedules
  });
}

export function setReturnSchedules(schedules) {
  Dispatcher.handleAction({
    type: 'SET_RETURN_SCHEDULES',
    data: schedules
  });
}