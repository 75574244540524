// UserStore.js
import jwt from 'jwt-decode';
import Dispatcher from '../dispatcher/Dispatcher';
import { EventEmitter } from 'events';

class UserStoreClass extends EventEmitter {

  constructor() {
    super();
    this.token = null;
    this.user = null;
    this.topbar = null;
    this.isLoggedIn = false;
    Dispatcher.register(this.actionDispatcher.bind(this));
  }

  addChangeListener(callback) {
    this.on('change', callback);
  }

  addTopbarListener(callback) {
    this.on('topbar', callback);
  }

  removeChangeListener(callback) {
    this.removeListener('change', callback);
  }

  removeTopbarListener(callback) {
    this.removeListener('topbar', callback);
  }

  getToken() {
    return this.token;
  }

  getUser() {
    return this.user;
  }

  getTopbar() {
    return this.topbar;
  }

  getLoggedIn() {
    return this.isLoggedIn;
  }

  emitChange() {
    this.emit('change');
  }

  emitTopbarChange() {
    this.emit('topbar');
  }

  actionDispatcher(payload) {
    switch (payload.action.type) {

      case 'LOGIN_USER':
        const token = payload.action.data;
        this.token = token;
        this.user = jwt(token);
        this.isLoggedIn = true;
        this.emitChange();
        break;

      case 'LOGOUT_USER':
        this.token = null;
        this.isLoggedIn = false;
        this.user = null;
        this.emitChange();
        break;

      case 'REGISTER_USER':
        this.token = payload.action.data;
        this.user = jwt(this.token);
        this.isLoggedIn = true;
        this.emitChange();
        break;
      case 'SET_USER':
        this.token = payload.action.data;
        this.user = jwt(this.token);
        this.isLoggedIn = true;
        this.emitChange();
        break;
      case 'SET_TOPBAR':
        this.topbar = (payload.action.data.topbar && payload.action.data.topbar.length > 0) ? payload.action.data.topbar[0] : null;
        this.emitTopbarChange();
        break;
      default:
        break;
    }
  }
}
const UserStore = new UserStoreClass();
export default UserStore;