import React from 'react';
import UserService from '../../services/user';
import UserStore from '../../stores/UserStore';
import InputMask from 'react-input-mask';

class AccountDetails extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.state = {
      user: JSON.parse(JSON.stringify(UserStore.getUser())),
      error: '',
      loading: false,
      success: false,
      validity: {
        email: {
          required: true,
          format: true
        },
        phone: {
          format: true
        },
        zip: {
          format: true
        },
        firstName: {
          format: true
        },
        lastName: {
          format: true
        },
        city: {
          format: true
        },
        state: {
          format: true
        }
      }
    }
  }

  handleInput(e) {
    this.setState({error: '', success: false});
    let user = this.state.user;
    user[e.target.name] = e.target.value;
    this.setState({[e.target.name+'_error']: ''});
    this.setState({user: user});
    if(e.target.required && !e.target.value) {
      let error = {};
      error[e.target.name+'_error'] = 'Required';
      this.setState(error);
    }
    else if(e.target.name === 'phone' && e.target.value && /\(\d{3}\)[\s.-]\d{3}[\s.-]\d{4}/.test(e.target.value) === false) {
      let error = {};
      error[e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
    else if(e.target.name === 'email' && e.target.value && this.emailRegex.test(e.target.value) === false) {
      let error = {};
      error[e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
    else if(this.refs[e.target.name].validity && !this.refs[e.target.name].validity.valid) {
      let error = {};
      error[e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
  }

  validateData() {
    let obj = {};
    for (let field in this.state.validity) {
      if (!this.state.user[field] && this.state.validity[field].required) {
        obj[field+'_error'] = 'Required';
      }
      else if(field === 'phone' && this.state.validity[field].format && this.state.user[field] && (/\(\d{3}\)[\s.-]\d{3}[\s.-]\d{4}/.test(this.state.user[field]) == false && /^\d{10}$/.test(this.state.user[field]) == false)) {
        obj[field+'_error'] = 'Invalid';
      }
      else if(field === 'zip' && this.state.validity[field].format && this.state.user[field] && /\d{5}([\-]\d{4})?/.test(this.state.user[field]) == false) {
        obj[field+'_error'] = 'Invalid';
      }
      else if(field === 'email' && this.state.validity[field].format && this.state.user[field] && this.emailRegex.test(this.state.user[field]) == false) {
        obj[field+'_error'] = 'Invalid';
      }
      else if(this.refs[field].validity && !this.refs[field].validity.valid) {
        obj[field+'_error'] = 'Invalid';
      }
      else {
        this.setState({[field+'_error']: ''});
      }
    }
    if (Object.keys(obj).length > 0) {
      this.setState(obj);
      return false;
    }
    else {
      return true;
    }
  }

  updateDetails(e) {
    e.preventDefault();
    if (!this.validateData()) {
      return;
    }
    this.setState({loading: true, error: '', success: false});
    let user = {
      email: this.state.user.email,
      firstName: this.state.user.firstName,
      lastName: this.state.user.lastName,
      city: this.state.user.city || '',
      state: this.state.user.state || '',
      address: this.state.user.address || '',
      zip: this.state.user.zip || '',
      country: this.state.user.country || '',
      phone: this.state.user.phone || ''
    };
    UserService.update(this.state.user.id, user)
    .then(() => {
      this.setState({loading: false, success: true})
    })
    .catch((err) => {
      console.log(err)
      console.log(JSON.stringify(err))
      this.setState({loading: false, error: (err.response.text || err.response.statusText)});
    });
  }

  onChange() {
    console.log(UserStore.getUser())
    this.setState({user: UserStore.getUser()});
  }

  componentDidMount() {
    UserStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    UserStore.removeChangeListener(this.onChange);
  }

  render() {
    return (
        <div className="overview">
            <div className="customerInformation">
                <div className="lSecHeader step-a">
                    <h4 className="heading4">Login Information</h4>
                    <p>Email will be used as your username to login.</p>
                </div>
                <div className="row clearfix">
                  <div className="col-sm-6 form-group">
                    <label className="formLable">E-MAIL</label>
                    <input required ref="email" name="email" value={this.state.user.email} onChange={this.handleInput.bind(this)} className="form-control username" placeholder="you@domain.com" />
                    <div className="error">{this.state.email_error}</div>
                  </div>
                </div>
            </div>
            <div className="registerForm">
                <div className="lSecHeader step-b">
                    <h4 className="heading4">Account Information</h4>
                    <p>The information we'll use each time you purchase.</p>
                </div>
                <div className="row clearfix">
                  <div className="col-sm-6 form-group">
                    <label className="formLable">FIRST NAME</label>
                    <input pattern="[A-Za-z]{1,32}" ref="firstName" required name="firstName" onChange={this.handleInput.bind(this)} value={this.state.user.firstName} type="text" className="form-control" placeholder="First Name" />
                    <div className="error">{this.state.firstName_error}</div>
                  </div>
                  <div className="col-sm-6 form-group">
                    <label className="formLable">LAST NAME</label>
                    <input pattern="[A-Za-z]{1,32}" ref="lastName" required name="lastName" onChange={this.handleInput.bind(this)} value={this.state.user.lastName} type="text" className="form-control" placeholder="Last Name" />
                    <div className="error">{this.state.lastName_error}</div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-sm-6 form-group">
                    <label className="formLable">ADDRESS</label>
                    <input pattern="[A-Za-z0-9\s.,]{1,32}" ref="address" name="address" onChange={this.handleInput.bind(this)} value={this.state.user.address} type="text" className="form-control" placeholder="Address" />
                    <div className="error">{this.state.address_error}</div>
                  </div>
                  <div className="col-sm-6 form-group">
                    <label className="formLable">CITY</label>
                    <input pattern="[A-Za-z\s]{1,32}" ref="city" name="city" onChange={this.handleInput.bind(this)} value={this.state.user.city} type="text" className="form-control" placeholder="City" />
                    <div className="error">{this.state.city_error}</div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-sm-4 form-group">
                    <label className="formLable">STATE</label>
                    <input pattern="[A-Za-z\s]{1,32}" ref="state" name="state" onChange={this.handleInput.bind(this)} value={this.state.user.state} type="text" className="form-control" placeholder="State" />
                    <div className="error">{this.state.state_error}</div>
                  </div>
                  <div className="col-sm-4 form-group">
                    <label className="formLable">COUNTRY</label>
                    <input pattern="[A-Za-z\s]{1,32}" ref="country" name="country" onChange={this.handleInput.bind(this)} value={this.state.user.country} type="text" className="form-control" placeholder="Country" />
                    <div className="error">{this.state.country_error}</div>
                  </div>
                  <div className="col-sm-4 form-group">
                    <label className="formLable">ZIP</label>
                    <input ref="zip" inputMode="numeric" pattern="(\d{5}([\-]\d{4})?)" name="zip" onChange={this.handleInput.bind(this)} value={this.state.user.zip} type="text" className="form-control" placeholder="Zip" />
                    <div className="error">{this.state.zip_error}</div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-sm-6 form-group">
                    <label className="formLable">PHONE</label>
                    <InputMask mask="(999) 999-9999" ref="phone" onChange={this.handleInput.bind(this)} value={this.state.user.phone} name="phone" className="form-control" placeholder="Phone Number" />
                    <div className="error">{this.state.phone_error}</div>
                  </div>
                </div>
                <div className="row form-group clearfix">
                  <div className="col-sm-12">
                    <button type="button" disabled={this.state.loading} onClick={this.updateDetails.bind(this)} className="btn btn-blue">SAVE</button>
                  </div>
                </div>
                {this.state.error && <div className="error server-error">Error: {this.state.error}</div>}
                {this.state.success && <div className="success server-success">Details Saved Successfully.</div>}
            </div>
        </div>
    )
  }
}

export default AccountDetails;