// application.js
import request from 'superagent';
import config from '../config';

class OrderService {
  registerAndCreateOrder(body) {
    return new Promise((resolve, reject) => {
      request.post(config.apiUrl+'orders/profile/new')
      .send(body)
      .set('Accept', 'application/json')
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(response.body);
        }
        else if(error){
          reject(error);
        }
        else{
          reject(response.body);
        }
      });
    })
  }

  createOrder(body) {
    return new Promise((resolve, reject) => {
      request.post(config.apiUrl+'orders/charge')
      .send(body)
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer '+localStorage.getItem('token'))
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(response.body);
        }
        else if(error){
          reject(error);
        }
        else{
          reject(response.body);
        }
      });
    })
  }

  createGift(body) {
    return new Promise((resolve, reject) => {
      request.post(config.apiUrl+'gift_cards')
      .send(body)
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer '+localStorage.getItem('token'))
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(response.body);
        }
        else if(error){
          reject(error);
        }
        else{
          reject(response.body);
        }
      });
    })
  }

  reschedule(body) {
    if(localStorage.getItem('token')) {
      return new Promise((resolve, reject) => {
        request.post(config.apiUrl+'orders/reschedule-with-token')
        .send(body)
        .set('Accept', 'application/json')
        .set('Authorization', 'Bearer '+localStorage.getItem('token'))
        .end((error, response) => {
          if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
            resolve(response.body);
          }
          else if(error){
            reject(error);
          }
          else{
            reject(response.body);
          }
        });
      })
    }
    else {
      return new Promise((resolve, reject) => {
        request.post(config.apiUrl+'orders/reschedule')
        .send(body)
        .set('Accept', 'application/json')
        .end((error, response) => {
          if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
            resolve(response.body);
          }
          else if(error){
            reject(error);
          }
          else{
            reject(response.body);
          }
        });
      })
    } 
  }

  verifyCoupon(userId, code, date, isElite) {
    return new Promise((resolve, reject) => {
      let url = config.apiUrl+'coupons/verify/' + code + '/' + date;
      if(userId && isElite) {
        url = url + '?user=' + userId + '&elite=true';
      }
      else if(userId) {
        url = url + '?user=' + userId;
      }
      else if(isElite) {
        url = url + '?elite=true';
      }
      request.get(url)
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer '+localStorage.getItem('token'))
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(response.body);
        }
        else if(error){
          reject(error);
        }
        else{
          reject(response.body);
        }
      });
    })
  }

  verifyGiftCard(code) {
    return new Promise((resolve, reject) => {
      let url = config.apiUrl+'gift_cards/verify/' + code;
      request.get(url)
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer '+localStorage.getItem('token'))
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(response.body);
        }
        else if(error){
          reject(error);
        }
        else{
          reject(response.body);
        }
      });
    })
  }
}

export default new OrderService();